<template>
  <v-card class="mb-12 pa-8 rounded-0" elevation="0" outlined>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card-title class="align-start position-relative pa-0 pb-4">
          <span class="font-weight-bold">Your Favourite Events</span>
          <v-spacer />
          <div class="position-absolute" style="width: 240px; right: 0; top: 0">
            <v-text-field outlined dense clearable name="search" label="Search" v-model="query.search" placeholder="Search" />
          </div>
        </v-card-title>
      </v-col>

      <v-col cols="12" sm="8" v-if="events.length > 0">
        <v-list dense class="pa-0">
          <v-list-item class="pa-0 mb-8" v-for="event in events" :key="event._id">
            <v-list-item-content style="flex: 240px 0">
              <v-img
                :src="$get400Image(event.image, event.imageUserId, event._id)"
                alt="Event-Image"
                width="240"
                height="160"
              />
            </v-list-item-content>
            <v-list-item-content class="px-8">
              <v-list-item-title>
                <div class="text--secondary body-2 font-weight-bold pb-4">
                  {{event.startsAt | dateWithDay}}
                </div>
                <div class="text--primary body-1 text-bold">{{ event.title }} with {{ event.performers && event.performers.length ? event.performers[0].name : '' }}</div>
                <div class="caption">Concert</div>
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex justify-end">
                <v-btn icon color="red" @click="$copy(getUrl(event))">
                  <v-icon>mdi-share-variant</v-icon>
                </v-btn>
                <v-btn icon color="red" @click="unLikedEvent(event)">
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="12" v-if="events.length === 0">
        <div>
          <v-card-title class="justify-center primary--text ">
            Currently no favourite available
          </v-card-title>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { UserService, PromoterEventService } from '@services';
export default {
  data() {
    return {
      query: {
        search: '',
      },
      events: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await UserService.getLikedEvents(this.query);
      if (response) {
        this.events = response.events;
      }
    },
    getUrl(event) {
      let url = `${window.location.protocol}//${window.location.host}`;
      // const port = window.location.port;
      // if (!port) url += `:${port}`;
      return `${url}/${event.slug}?utm_source=link&utm_medium=social&utm_campaign=tickettext`;
    },
    async unLikedEvent(event) {
      const response = await PromoterEventService.unLikedEvents([event._id]);
      if (response) {
        this.getData()
      }
    },
  },
  watch: {
    'query.search': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.search && this.query.search.length > 2) {
          this.getData();
        }
        if (!this.query.search) {
          this.getData();
        }
      }, 600);
    },
  },
  
};
</script>
