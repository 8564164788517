<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12">
          <div class="text-h4 primary--text">Your Account</div>
        </v-col>
        <v-col cols="12" sm="12" class="py-0">
          <v-divider />
        </v-col>
        <v-col cols="12" sm="12">
          <v-tabs>
            <v-tab>
              <v-icon class="primary--text pr-2" href="#tab-profile">mdi-account</v-icon>
              <span>Profile</span>
            </v-tab>
            <v-tab>
              <v-icon class="primary--text pr-2" href="#tab-orders">mdi-calendar-outline</v-icon>
              <span>Orders</span>
            </v-tab>
            <v-tab>
              <v-icon class="primary--text pr-2" href="#tab-favourites">mdi-star-outline</v-icon>
              <span>Favourites</span>
            </v-tab>
            <v-tab>
              <v-icon class="primary--text pr-2" href="#tab-settings">mdi-cog-outline</v-icon>
              <span>Settings</span>
            </v-tab>
            <v-tab-item class="pt-8"> <profile :user="user" /> </v-tab-item>
            <v-tab-item class="pt-8"> <orders /></v-tab-item>
            <v-tab-item class="pt-8"> <favourites /></v-tab-item>
            <v-tab-item class="pt-8"> <settings /></v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { UserService } from '@services';
import profile from './profile';
import favourites from './favourites';
import orders from './orders';
import settings from './settings';

export default {
  components: {
    profile,
    orders,
    settings,
    favourites,
  },
  data() {
    return {
      user: {},
      socialProfiles: {},
      payouts: {},
      orders: [],
    };
  },
  created() {
    this.getCurrentUser();
    // this.getOrganiserData();
    // this.getPayoutData();
    this.getMyOrders();
  },
  methods: {
    async getCurrentUser() {
      this.user = await UserService.me();
    },
    async getMyOrders() {
      const response = await UserService.getMyOrders();
      if (response) {
        this.orders = response;
      }
    },
    async getOrganiserData() {
      const response = await UserService.getOrganiserInfo(this.$user.organiser);
      if (response) {
        this.socialProfiles = response.socialProfiles || {};
      }
    },
    async getPayoutData() {
      const organisationData = await UserService.checkpayment(this.$user.organiser);
      if (organisationData) {
        this.payouts = organisationData;
        const accountNumber = organisationData.last4;
        this.payouts.accountNumber = new Array(accountNumber.length + 1).join('*') + accountNumber;
      }
    },
  },
};
</script>
