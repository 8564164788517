var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","clearable":"","required":"","name":"firstName","rules":[
          v => !!v || 'First Name is required',
          v => (v && v.length < 20) || 'First name must less than 20 character',
        ],"label":"First Name","placeholder":"John Doe"},model:{value:(_vm.data.firstName),callback:function ($$v) {_vm.$set(_vm.data, "firstName", $$v)},expression:"data.firstName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","clearable":"","required":"","name":"lastName","rules":[
          v => !!v || 'Last Name is required',
          v => (v && v.length < 20) || 'Last name must less than 20 character',
        ],"label":"Last Name","placeholder":"John Doe"},model:{value:(_vm.data.lastName),callback:function ($$v) {_vm.$set(_vm.data, "lastName", $$v)},expression:"data.lastName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"type":"email","outlined":"","dense":"","clearable":"","required":"","rules":[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid'],"name":"email","label":"Email","placeholder":"johndoe@gmail.com"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{attrs:{"block":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.subscribe()}}},[_vm._v(" Subscribe ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }