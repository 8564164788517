<template>
  <div>
    <v-card-actions class=" py-8" v-if="!isShowUnavailablePage && !isCartHaveValue">
      <v-row justify="space-between">
        <v-col cols="12" sm="4" md="4">
          <span class="text-bold text--primary">Share with friends</span> <share-with-friends :event="event" />
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <v-btn block color="primary" @click="bookTickets()"> Book Tickets </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

    <v-card-title class="px-md-12 pt-8" v-if="isShowUnavailablePage && !isCartHaveValue">
      <span v-if="isEventCancelledError">This event is cancelled. Please contact TicketText HelpDesk for more info.</span>
      <span v-if="isActiveError"
        >This event is not available. Please contact TicketText HelpDesk for more info.</span
      >
      <span v-if="isAvailableError"
        >Tickets available from {{ event.availableFrom | fullDateAndTime }} <span v-if="event && event.availableUntil">to
        {{ event.availableUntil | fullDateAndTime }}.</span></span
      >
      <span v-if="isSoldOutError"
        >This event is sold out. Enter your details below. We will notify you of upcoming related events.</span
      >
    </v-card-title>

    <v-card-title class="px-md-12 pt-8" v-if="isCartHaveValue">
      You can only reserve tickets for one events at a time, please click below to complete your existing transaction or
      remove all items.
    </v-card-title>

    <v-row v-if="isCartHaveValue" justify="end" class="px-md-12 pb-8">
      <v-col cols="12" sm="4" md="3">
        <v-btn block color="primary" @click="returnBasket()">Return to my basket</v-btn>
      </v-col>
    </v-row>

    <v-divider />

    <div class="pa-md-12">
      <v-form ref="form" v-if="isShowUnavailablePage && !isCartHaveValue" v-model="valid" lazy-validation>
        <Subscription :event="event" />
      </v-form>

      <v-row>
        <v-col cols="12" sm="8">
          <v-card-title class="font-weight-bold text-h5">About this Event</v-card-title>
          <v-card-text v-html="event.blurbMain"> </v-card-text>

          <v-card-title v-if="event.blurbExtra" class="font-weight-bold text-h8"
            >Important Event Information</v-card-title
          >
          <v-card-text v-if="event.blurbExtra" v-html="event.blurbExtra"> </v-card-text>

          <v-card-title v-if="event.infoMessage || event.ageRestrictions" class="font-weight-bold text-h8">Age restrictions</v-card-title>
          <v-card-text class="font-weight-bold" v-if="event.ageRestrictions">Over {{event.ageRestrictions}}s only. ID may be required to gain entry. Failure to provide valid ID may result in non-admission, without eligibility for a refund.</v-card-text>
          <v-card-text v-if="event.infoMessage" v-html="event.infoMessage"> </v-card-text>

          <v-card-title v-if="event.timingInformation" class="font-weight-bold text-h8"
            >Timing Information</v-card-title
          >
          <v-card-text v-if="event.timingInformation" v-html="event.timingInformation"> </v-card-text>

          <v-card-title v-if="event.additionalInformation" class="font-weight-bold text-h8"
            >Event terms & conditions</v-card-title
          >
          <v-card-text v-if="event.additionalInformation" v-html="event.additionalInformation"> </v-card-text>

          <v-card-title v-if="event.venue && event.venue.information" class="font-weight-bold text-h8">Venue Information</v-card-title>
          <v-card-text v-if="event.venue && event.venue.information" v-html="event.venue.information"> </v-card-text>

          <v-card-title>Performing:</v-card-title>
          <v-card-subtitle style="padding: 0" v-if="!isPerformerMore">
            <v-card-text>
              <span v-for="(item, index) in event.performers" :key="item._id">
                <span v-if="index < 5"> <span v-if="index != 0">,</span> {{ item.name }}</span></span
              >
              <a
                class="text-h8"
                v-if="event.performers && event.performers.length > 5"
                @click="showMore(event.performers)"
              >
                View More</a
              >
            </v-card-text>
          </v-card-subtitle>
          <v-card-subtitle style="padding: 0" v-if="isPerformerMore">
            <v-card-text>
              <span v-for="(item, index) in event.performers" :key="item._id">
                <span> <span v-if="index != 0">,</span> {{ item.name }}</span></span
              >
              <a
                class="text-h8"
                @click="showLess()"
              >
                View Less</a
              >
            </v-card-text>
          </v-card-subtitle>

          <v-card-subtitle class="text-bold text--primary">Ticket Price</v-card-subtitle>
          <template class="pt-8">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Ticket type 
                    </th>
                    <th class="text-left">
                      Cost
                    </th>
                    <th class="text-left">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in event.ticketCategories" :key="item._id">
                    <td>{{ item.category.name }}</td>
                    <td>{{ (item.price + item.bookingFee) | currency }}</td>
                    <td>
                      <span v-if="item.availableCount <= item.purchaseCount">Sold Out</span>
                      <span cols="6" sm="3" md="2" class="px-md-0" v-else-if="checkTicketAvailabe(item)">
                        Unavailable
                      </span>
                      <span v-else>
                        Available
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>

          <v-card-subtitle v-if="event.tags && event.tags.length > 0" class="text-bold text--primary"
            >Tags:</v-card-subtitle
          >
          <v-card-text>
            <template v-for="tag in event.tags">
              <template v-if="tag">
                <v-chip :key="tag" class="rounded mr-2" color="primary" outlined> {{ tag }} </v-chip>
              </template>
            </template>
          </v-card-text>

          <v-card-subtitle class="text-bold text--primary">Share with friends</v-card-subtitle>
          <v-card-text>
            <share-with-friends :event="event" />
          </v-card-text>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card-title class="text-bold text-h5">Event Category</v-card-title>
          <v-card-text>
            <span v-if="event.genre && event.genre.name">{{ event.genre.name }}</span> <span v-if="event.subCategory && event.subCategory.name">- {{ event.subCategory.name}}</span>
          </v-card-text>

          <v-card-title class="text-bold text-h5">Date and Time</v-card-title>
          <v-card-text class="subtitle-2 d-flex flex-column align-start">
            <span>{{ event.startsAt | fullday }}</span>
            <span>{{ event.startsAt | time }} - {{ event.endsAt | time }}</span>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on"><span class="primary--text">Add to calendar</span></a>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in addCalendarOptions"
                  :key="index"
                >
                  <v-list-item-icon class="mr-2">
                    <a><v-icon color="primary">{{item.icon}}</v-icon></a> 
                  </v-list-item-icon>
                  <v-list-item-title @click="addEventToCalender(item, event)"><a>{{ item.title }}</a></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-text>
          <v-card-text class="subtitle-2 d-flex flex-column align-start">
            <span>Location:</span>
            <span>{{ event.venue ? event.venue.name : '' }}</span>
            <span v-if="event.venue && event.venue.address && event.venue.address.line1">
              {{event.venue.address.line1}},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.line2">
              {{event.venue.address.line2}},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.city">
              {{event.venue.address.city}},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.postcode">
              {{event.venue.address.postcode}},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.country">
              {{event.venue.address.country}}
            </span>
          </v-card-text>

          <v-card-title v-if="isShowUnavailablePage" class="text-bold text-h5"> Ticket Available Date</v-card-title>
          <v-card-text v-if="isShowUnavailablePage" class="subtitle-2 d-flex flex-column align-start">
            <span>Start Date - {{ event.availableFrom | fullDateAndTime }}</span>
            <span>End Date - {{ event.availableUntil | fullDateAndTime }}</span>
          </v-card-text>

          <v-card-title v-if="event && event.doorsAt" class="text-bold text-h5"> Door Time</v-card-title>
          <v-card-text v-if="event && event.doorsAt" class="subtitle-2 d-flex flex-column align-start">
            <span>{{ event.doorsAt | fullday }}</span>
            <span>{{ event.doorsAt | time }}</span>
          </v-card-text>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ShareWithFriends from './share-with-friends';
import Subscription from './subscription';
import { UserService } from '@services';

export default {
  components: {
    ShareWithFriends,
    Subscription,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      reservationStatus: {},
      data: {},
      valid: false,
      loading: false,
      isShowUnavailablePage: false,
      isCartHaveValue: false,
      isActiveError: false,
      isAvailableError: false,
      isSoldOutError: false,
      isPerformerMore: false,
      isEventCancelledError: false,
      addCalendarOptions: this.addEventCalendarOptions(),
    };
  },
  created() {
    let seats = JSON.parse(window.localStorage.getItem('selectedSeats'));
    let otheseats = (seats || []).filter(s => s.event === this.event._id);
    if ((seats || []).length !== (otheseats || []).length) {
      this.isCartHaveValue = true;
    }
    let soldOutCount = 0;
    for (let index = 0; index < this.event.ticketCategories.length; index++) {
      const element = this.event.ticketCategories[index];
      if (element.availableCount <= element.purchaseCount) {
        soldOutCount = soldOutCount + 1;
      }
    }
    if(this.event.status === 'cancelled'){
       this.isEventCancelledError = true;
       this.isShowUnavailablePage = true;
    } else if (!['active', 'private'].includes(this.event.status)) {
      this.isShowUnavailablePage = true;
      this.isActiveError = true;
    } else if (
      this.event.availableFrom &&
      this.event.availableUntil &&
      !moment().isBetween(moment(this.event.availableFrom), moment(this.event.availableUntil), 'days', true)
    ) {
      this.isShowUnavailablePage = true;
      this.isAvailableError = true;
    } else if (
      this.event.availableFrom &&
      moment().isBefore(moment(this.event.availableFrom))
    ) {
      this.isShowUnavailablePage = true;
      this.isAvailableError = true;
    } else if (
      this.event.availableUntil &&
      moment().isAfter(moment(this.event.availableUntil))
    ) {
      this.isShowUnavailablePage = true;
      this.isAvailableError = true;
    } else if (soldOutCount === this.event.ticketCategories.length) {
      this.isShowUnavailablePage = true;
      this.isSoldOutError = true;
    }
    if (this.$route.query.utm_source) {
      localStorage.setItem('utm_source', JSON.stringify({ 
        utm_source: this.$route.query.utm_source,
        utm_medium: this.$route.query.utm_medium,
        utm_campaign: this.$route.query.utm_campaign,
        utm_eventid: this.$route.params.id,
      }))
    }
  },
  methods: {
    createCookie(name, value, hours){
      if (hours) {
          var date = new Date();
          date.setTime(date.getTime()+(hours*60*60*1000));
          var expires = "; expires="+date.toGMTString();
      } else {
          var expires = "";
      }
      document.cookie = name+"="+value+expires+"; path=/";
    },
    async bookTickets() {
      if (!this.$user) {
        const userResponse = await UserService.createTemporaryUser();
        if (userResponse) {
          localStorage.setItem('auth.token', userResponse.token);
          this.$store.set('auth/user', userResponse.user);
        }
      }
      // user activity track start
      this.manageUserActivity({event:'ticketselect'});
      // user activity track end
      const query = Object.assign({}, this.$route.query);
      query.page = 'ticketselect';
      this.$next({ name: 'visitor.event', params: { id: this.$route.params.id }, query: query });
      this.$root.$emit('bookingPageChange', 'ticketselect');
      this.$bus.$emit('onTicketSelectPage');
      setTimeout(() => {
        this.$scrollToElement('ticket-options');
      },200);
    },
    getUrl() {
      let url = `${window.location.protocol}//${window.location.host}`;
      // const port = window.location.port;
      // if (port) url += `:${port}`;
      return `${url}/${this.event.slug}`;
    },
    returnBasket() {
      this.$root.$emit('bookingPageChange', 'ticketselect');
      const query = Object.assign({}, this.$route.query);
      query.page = 'ticketselect'
      this.$next({
        name: 'visitor.event',
        params: { id: window.localStorage.getItem('bookedEvent') },
        query: query,
      });
      
      // window.location.reload();
    },
    showMore() {
      this.isPerformerMore = true;
    },
    showLess() {
      this.isPerformerMore = false;
    },
    checkTicketAvailabe(ticket) {
      let isAvailableError = false;
      if (
        ticket.availableFrom &&
        ticket.availableUntil &&
        !moment().isBetween(moment(ticket.availableFrom), moment(ticket.availableUntil), 'days', true)
      ) {
        isAvailableError = true;
      } else if (ticket.availableFrom && moment().isBefore(moment(ticket.availableFrom))) {
        isAvailableError = true;
      } else if (ticket.availableUntil && moment().isAfter(moment(ticket.availableUntil))) {
        isAvailableError = true;
      }
      if (this.isShowUnavailablePage) {
         isAvailableError = true;
      }
      return isAvailableError;
    }
  },
};
</script>
