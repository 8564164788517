<template>
  <div v-if="events.length > 0">
    <v-row class="px-sm-16 py-0 ma-0 position-relative w-100">
      <v-col cols="12" sm="12" class="pa-0">
        <v-card-title class="h4 text-bold text--primary justify-center">
          Other Events You May Like
        </v-card-title>
      </v-col>
    </v-row>

    <v-row class="px-md-16 pt-4 pb-16 ma-0 position-relative w-100">
      <v-col cols="12" sm="6" md="4" v-for="eve in events" :key="eve._id">
        <v-card class="mx-auto" @click="openEvent(eve)">
          <v-img class="white--text align-end" :aspect-ratio="16/9" :src="$get400Image(eve.image, eve.imageUserId, eve._id)">
            <v-card-title class="justify-end">
              <v-btn class="mx-2 white" fab outlined small @click.stop="$copy(getUrl(eve))" color="red">
                <v-icon dark> mdi-share-variant </v-icon>
              </v-btn>

              <v-btn class="mx-2 white" v-if="eve.eventlikeds.length > 0" @click.stop="unLikedEvent(eve)" fab outlined small color="red">
                <v-icon dark> mdi-heart </v-icon>
              </v-btn>
              <v-btn class="mx-2 white" v-if="eve.eventlikeds.length === 0" @click.stop="likedEvent(eve)" fab outlined small color="red">
                <v-icon dark> mdi-heart-outline </v-icon>
              </v-btn>
            </v-card-title>
          </v-img>
          <v-list-item three-line class="grey lighten-4">
            <v-list-item-avatar tile class="justify-start" size="60">
              <v-card-title class="h2 flex-column align-start pa-0">
                <span class="primary--text">{{ eve.startsAt | day }}</span>
                <span>{{ eve.startsAt | month }}</span>
              </v-card-title>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text--primary subtitle-1 mb-4">
                <span class="text-bold">{{ eve.title}}</span>
                <br />
                <span class="font-weight-semibold">{{eve.subtitle}}</span>
              </v-list-item-title>

              <v-list-item-subtitle class="button subtitle-2 font-weight-semibold">
                <span>{{eve.startsAt | dateWithDay}}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { PromoterEventService } from '@services';

export default {
  props: {
    event: Object,
  },
  data() {
    return {
      events: [],
    };
  },
  created() {
    this.getEvents()
  },
  methods: {
    async getEvents() {
      const response = await PromoterEventService.getSameEvents({tags: this.event.tags, genre: this.event.genre._id, id: this.event._id});
      if (response) {
        this.events = response
      }
    },
    getUrl(event) {
      let url = `${window.location.protocol}//${window.location.host}`;
      return `${url}/${event.slug}`;
    },
    openEvent(event) {
      const url = this.getUrl(event);
      window.open(url);
    },
    async unLikedEvent(event) {
      const response = await PromoterEventService.unLikedEvents([event._id]);
      if (response) {
        this.getEvents()
      }
    },
    async likedEvent(event) {
      const response = await PromoterEventService.likedEvents([event._id]);
      if (response) {
        this.getEvents()
      }
    }
  },
};
</script>
