<template>
  <div :key="id">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!--v-img
      :aspect-ratio="$vuetify.breakpoint.xs ? 16 / 9 : 10 / 4"
      :src="$getOrignalImage(event.image, event.imageUserId, event._id)"
      class="position-absolute top hidden-print-only hidden-xs-only"
      style="height: 70vh; width: 100%; object-fit: contain; left: 0"
    >
    </v-img-->

    <v-row id="print" class=" mb-16 ma-0 position-relative w-100" v-show="isDataLoaded">
      <v-col cols="12" sm="12" class="px-0 ">
        <v-card outlined class="pa-0 ml-2">
          <v-card-text class="pa-0 ">
            <v-row class="ma-0">
              <v-col cols="12" sm="7" class="pa-0 hidden-print-only">
                <v-layout justify="end" v-if="event.status === 'cancelled'">
                  <v-sheet class="mt-4" style="z-index:1;position:absolute;">
                    <v-chip label color="red" text-color="white" class="rounded-0">
                      CANCELLED
                    </v-chip>
                  </v-sheet>
                </v-layout>
                <div ref="myBanner">
                  <v-responsive :aspect-ratio="16 / 9">
                    <v-img :src="srcValue" :lazy-src="$getOrignalImage()" />
                  </v-responsive>
                </div>
              </v-col>

              <v-col cols="12" sm="5" class="pa-0">
                <v-card-title class="text-h6 flex-column font-weight-bold align-start">
                  <span class="primary--text" v-if="event.startsAt"
                    >{{ event.startsAt | day }} {{ event.startsAt | monthyear }} at {{ event.startsAt | time }}</span
                  >
                  <!-- <span class="text--primary" v-if="event.startsAt"
                    >{{ event.startsAt | monthyear }} at {{ event.startsAt | time }}</span
                  > -->
                </v-card-title>

                <v-card-title class="py-2 py-sm-4">
                  <span class="text-h6 text--primary text-bold">
                    {{ event.title }} {{ event.performers && event.performers.length ? 'with' : '' }}<br />
                    {{ event.performers && event.performers.length ? event.performers[0].name : '' }}
                  </span>
                </v-card-title>

                <v-card-subtitle class="py-4 py-sm-4 text--primary" v-if="event.subtitle">
                  {{ event.subtitle }}
                </v-card-subtitle>

                <v-card-title class="py-2 py-sm-4" v-if="event && event.organiser && event.organiser._id">
                  <span> Event by </span>
                  <span class="ml-2 font-weight-bold text--primary">
                    <router-link :to="{ name: 'visitor.event-promoter', params: { id: event.organiser._id } }">
                      {{ event.organiser ? event.organiser.name : '' }}
                    </router-link>
                  </span>
                </v-card-title>

                <v-card-subtitle class="py-4 py-sm-4" v-if="event.venue">
                  <v-icon aria-hidden="false" class="primary--text pr-2"> mdi-map-marker-outline </v-icon>
                  <span class="font-weight-bold text--primary">Location:</span><br />
                  <span class="font-weight-bold text--primary" style="padding-left: 8px">
                    <router-link :to="{ name: 'visitor.event-venue', params: { id: event.venue.name } }">
                      <span>{{ event.venue ? event.venue.name : '' }}</span
                      ><br />
                    </router-link>
                  </span>
                  <span class="pl-2" v-if="event.venue && event.venue.address && event.venue.address.line1">
                    {{ event.venue.address.line1 }}<br />
                  </span>
                  <span class="pl-2" v-if="event.venue && event.venue.address && event.venue.address.line2">
                    {{ event.venue.address.line2 }},<br />
                  </span>
                  <span class="pl-2" v-if="event.venue && event.venue.address && event.venue.address.city">
                    {{ event.venue.address.city }},<br />
                  </span>
                  <span class="pl-2" v-if="event.venue && event.venue.address && event.venue.address.postcode">
                    {{ event.venue.address.postcode }},<br />
                  </span>
                  <span class="pl-2" v-if="event.venue && event.venue.address && event.venue.address.country">
                    {{ event.venue.address.country }}
                  </span>
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <!-- <div v-if="!$user && isDataLoaded">
              <event-register :event="event" />
            </div> -->

            <div v-if="isDataLoaded">
              <event-book-ticket v-if="page === 'eventbooked'" :event="event" />
            </div>

            <div v-if="isDataLoaded && !isShowUnavailablePage">
              <event-select-ticket v-show="page === 'ticketselect'" :event="event" />
            </div>

            <div v-if="isDataLoaded && !isShowUnavailablePage">
              <event-place-order v-show="page === 'placeorder'" :event="event" />
            </div>

            <div v-if="isDataLoaded">
              <event-ticket v-show="page === 'ticketbooked'" :event="event" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <similar-events v-if="$user && $user.role !== 'temporary' && isDataLoaded" :event="event" />
    <similar-events-without-login v-if="!$user && isDataLoaded" :event="event" />
    <similar-events-without-login v-if="$user && $user.role === 'temporary' && isDataLoaded" :event="event" />
  </div>
</template>

<script>
import moment from 'moment';
import { PromoterEventService, UserService, EventSurveyService, SurveyService } from '@services';
import EventRegister from './event-register';
import EventBookTicket from './event-book-ticket';
import SimilarEvents from './similar-events';
import EventSelectTicket from './event-select-ticket';
import EventPlaceOrder from './event-place-order';
import EventTicket from './event-ticket';
import SimilarEventsWithoutLogin from './similar-events-without-login';

export default {
  components: {
    EventRegister,
    EventBookTicket,
    SimilarEvents,
    EventSelectTicket,
    EventPlaceOrder,
    EventTicket,
    SimilarEventsWithoutLogin,
  },

  data() {
    return {
      event: {},
      overlay: true,
      isDataLoaded: false,
      isShowUnavailablePage: false,
      page: 'eventbooked',
      id: '',
      srcValue: '',
    };
  },
  metaInfo: {
    meta: [
      {
        name: 'og:title',
        content: 'Child description.',
      },
      {
        name: 'og:description',
        content: 'Child description.',
      },
    ],
  },
  mounted() {
    this.init();
  },
  unmounted() {},
  methods: {
    init() {
      this.isDataLoaded = false;
      this.id = this.$route.params.id;
      if (this.$route.query.page === 'ticketbooked') {
        this.page = 'ticketbooked';
      } else if (this.$user && this.$route.query.page === 'ticketselect') {
        this.page = 'ticketselect';
      } else if (this.$user && this.$route.query.page === 'placeorder') {
        this.page = 'placeorder';
      } else if (this.$user && this.$route.query.page === 'ticketbooked') {
        this.page = 'ticketbooked';
      }
      this.getEvent();
      localStorage.removeItem('isEventPageSignup');
      this.getCurrentUser();
      this.$root.$on('bookingPageChange', options => {
        this.page = options;
        this.event.page == this.page;
      });
      this.$root.$on('checkStripeEnabled', () => {
        if (!window.Stripe) {
          this.securityConfirm();
          return;
        }
      });
    },
    changeMeta() {
      const descElTitle = document.querySelector('head meta[name="og:title"]');
      descElTitle.setAttribute('content', this.event.title);
      const descEl = document.querySelector('head meta[name="description"]');
      descEl.setAttribute('content', this.getTitle());
      debugger;
    },
    getTitle() {
      let title = '';

      if (this.event.page == 'eventbooked') {
        title = `I've just bought a ticket`;
      } else {
        title = `I am planning to attend an event`;
      }

      if (this.event.performers && this.event.performers[0]?.name) {
        title += ` to see ${this.event.performers[0].name}`;
      }

      title += ` on ${moment(this.event.startsAt).format('MMMM Do')}`;

      if (this.event.venue?.name) {
        title += ` at ${this.event.venue.name}.`;
      }

      return title;
    },
    async getEvent() {
      if (this.$route.params.id) {
        const response = await PromoterEventService.getById(this.$route.params.id);
        this.overlay = false;
        if (response) {
          response.ticketCategories.sort(function(a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return a.sortOrder - b.sortOrder;
          });
          this.event = response;
          
          const surveyEvents = await SurveyService.getByEvent(this.event._id);
          if(this.event.isHaveSurvey) {
            let eventSurvey;
            eventSurvey = await EventSurveyService.getByEvent(response._id);
            if (eventSurvey) {
              this.event.survey = [ eventSurvey, ...surveyEvents];
            } else {
              this.event.survey = [...surveyEvents];
            }
          } else {
            this.event.survey = [...surveyEvents];
          }
          for (let index = 0; index < this.event.survey.length; index++) {
            const element = this.event.survey[index];
            for (let i = 0; i < element.questions.length; i++) {
              const ele = element.questions[i];
              this.event.survey[index].questions[i].answer = ''
              if(this.event.survey[index].questions[i].questionType === 'checkbox') {
                this.event.survey[index].questions[i].answer = []
              }
            }
          }
          console.log("🚀 ~ file: index.vue:247 ~ getEvent ~ this.event.survey", this.event.survey)
          // for (let index = 0; index < eventSurvey.questions.length; index++) {
          //   const element = eventSurvey.questions[index];
          //   eventSurvey.questions[index].answer = ''
          //   if(eventSurvey.questions[index].questionType === 'checkbox') {
          //     eventSurvey.questions[index].answer = []
          //   }
          // }
          // this.event.survey = eventSurvey;
          this.setSrcValue(this.event);
          this.isDataLoaded = true;
          if (
            (this.event.availableFrom &&
              this.event.availableUntil &&
              !moment().isBetween(moment(this.event.availableFrom), moment(this.event.availableUntil), 'days', true)) ||
            !['active', 'private'].includes(this.event.status)
          ) {
            this.isShowUnavailablePage = true;
            // this.page = 'eventbooked';
          }

          this.event.page == this.page;
          // this.changeMeta();
        }
      }
    },
    async getCurrentUser() {
      if (this.$user) {
        await UserService.me();
      }
    },
    showMore(performers) {
      this.$root.$emit('performers-list', performers);
    },
    setSrcValue(event) {
      setTimeout(() => {
        const clientWidth = _.get(this, '$refs.myBanner.clientWidth') || 1600;
        const width = parseInt(clientWidth * window.devicePixelRatio);
        console.log('www', width, clientWidth, this.getWidth(width), this.$refs.myBanner.clientWidth);
        this.srcValue = this.$getOrignalImageByWidth(event.image, event.imageUserId, event._id, this.getWidth(width));
      }, 200);
    },
    getWidth(width) {
      if (width <= 100) {
        return 100;
      } else if (width <= 400) {
        return 400;
      } else if (width <= 800) {
        return 800;
      } else if (width <= 1200) {
        return 1200;
      } else if (width <= 1600) {
        return 1600;
      } else if (width <= 2000) {
        return 2000;
      } else {
        return 2000;
      }
    },
  },
  watch: {
    '$route.path'() {
      this.init();
    },
  },
};
</script>
