<template>
  <div id="ticket-options">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-divider />

    <v-card-title class="pa-4 pa-md-12 hidden-xs-only" v-if="event.ticketCategories.length > 0 && isTicketAvailable && !isCartHaveValue">
      <v-row>
        <v-col class="subtitle-1 text-bold" cols="12" sm="4">Select your tickets</v-col>
        <v-col cols="12" sm="4">
          <div v-if="(selectedSeats || []).length > 0" style="justify-content: center" class="subtitle-2 d-flex">
            <span>Time left:</span> <timer iscarthide="hide" class="ml-2" />
          </div>
        </v-col>
        <v-col class="subtitle-1 text-bold text-decoration-underline" style="text-align: end" cols="12" sm="4"
          >Your order so far</v-col
        >
      </v-row>
    </v-card-title>

    <v-card-title v-if="isCartHaveValue">
      <v-card-title class="px-md-12 pt-8" >
        You can only reserve tickets for one events at a time, please click below to complete your existing transaction
        or remove all items.
      </v-card-title>

      <v-row  justify="end" class="px-md-12 pb-8">
        <v-col cols="12" sm="4" md="3">
          <v-btn block color="primary" @click="returnBasket()">Return to my basket</v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-title
      class="px-12 py-8  justify-space-between"
      v-if="event.ticketCategories.length === 0 || !isTicketAvailable"
    >
      <span class="subtitle-1"
        >This event is sold out. Enter your details below. We will notify you of upcoming related events.</span
      >
    </v-card-title>

    <v-divider />

    <div class="pa-4 pa-md-12" v-if="event.ticketCategories.length === 0 || !isTicketAvailable">
      <Subscription :event="event" />
    </div>

    <div class="pa-4 pa-md-12" v-if="event.ticketCategories.length > 0 && isTicketAvailable && !isCartHaveValue">
      <v-row>
        <v-col class="outlined-xs-only" cols="12" sm="7" v-if="eventType === 'UnReserved'">
          <div class="hidden-sm-and-up text-center text-h6 mb-4 text-bold">Select your tickets</div>

          <v-row v-for="(item, index) in event.ticketCategories" :key="index">
            <v-col cols="12" class="hidden-sm-and-up">
              <v-divider />
            </v-col>

            <v-col cols="12" sm="6" md="7">
              <div class="text-bold">{{ item.category.name }}</div>
            </v-col>

            <v-col cols="6" sm="3" md="3" class="text-center text-sm-right">
              <div class="text--primary text-bold ">{{ (item.price + item.bookingFee) | currency }}</div>
              <div>
                <span class="pr-1 text--primary">(Inc</span>
                <span class="text--primary">{{ item.bookingFee | currency }}</span>
                <span class="pl-1 text--primary">fees)</span>
              </div>
            </v-col>

            <v-col cols="6" sm="3" md="2" class="px-md-0" v-if="item.availableCount <= item.purchaseCount">
              Sold Out
            </v-col>

            <v-col cols="6" sm="3" md="2" class="px-md-0" v-else-if="checkTicketAvailabe(item)">
              Unavailable
            </v-col>

            <v-col cols="6" sm="3" md="2" class="px-md-0" v-else>
              <v-icon
                class="primary--text mr-2"
                :disabled="titcketCount[item._id] === 0 || !titcketCount[item._id]"
                size="20"
                @click="deSelectSeat(item, index)"
              >
                mdi-minus-circle-outline
              </v-icon>
              <span class="text--primary text-bold text-center" :key="titcketCount[item._id]">{{
                titcketCount[item._id] || 0
              }}</span>
              <v-icon
                :disabled="
                  disableTicketCategory[item._id] ||
                    (selectedSeats || []).length === event.purchaseLimit ||
                    titcketCount[item._id] === item.purchaseLimit
                "
                class="primary--text ml-2"
                size="20"
                @click="selectSeat(item)"
              >
                mdi-plus-circle-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="subtitle-1 primary--text text-bold cursor-pointer"
              cols="12"
              sm="4"
              v-if="event && event.addson && event.addson.length"
              >Additional Items
              <v-icon v-if="!isShowAdditional" @click="showAdditionalItem()" class="primary--text"
                >mdi-menu-down</v-icon
              >
              <v-icon v-if="isShowAdditional" @click="showAdditionalItem()" class="primary--text"> mdi-menu-up</v-icon>
            </v-col>
            <v-col col="12" sm="12" v-if="isShowAdditional">
              <v-row v-for="(item, index) in event.addson" :key="item.addson">
                <v-col cols="12" class="hidden-sm-and-up">
                  <v-divider />
                </v-col>

                <v-col cols="12" sm="6" md="7" style=" display: -webkit-inline-box;">
                  <v-img
                    style="width: 100px"
                    max-height="100"
                    :src="$getAddon200Image(item.addon._id, item.addon.image)"
                  />
                  <div class="ml-3">
                    <div class="text-bold">
                      {{ item.addon.name }}
                    </div>
                    <div>
                      <div class="addon-descr" v-html="item.addon.description"></div>
                      <div class="pb-1">
                        {{ item.addon.valueName }}
                      </div>
                      <div style="max-width:200px;">
                        <v-select
                          v-model="item.attributes._id"
                          dense
                          outlined
                          :items="getAddonOptions(item)"
                          :item-text="'value'"
                          :item-value="'id'"
                          label="Choose Item"
                          @change="changeAttributeOption(item, index, item.attributes._id)"
                        />
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="3" class="text-center text-sm-right">
                  <div
                    class="text--primary text-bold "
                    v-if="item && item.selectedAttribute && item.selectedAttribute.price"
                  >
                    {{ item.selectedAttribute.price | currency }}
                  </div>
                  <div class="text--primary text-bold " v-else>{{ 0 | currency }}</div>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="px-md-0" v-if="checkTicketQuantity(item)">
                  Sold Out
                </v-col>
                <v-col cols="6" sm="3" md="2" class="px-md-0" v-else-if="checkTicketAvailabe(item)">
                  Unavailable
                </v-col>
                <v-col cols="6" sm="3" md="2" class="px-md-0" v-else>
                  <v-icon
                    class="primary--text mr-2"
                    size="20"
                    :disabled="disableAddonAttributeOnDeselect(item)"
                    @click="deSelectAddson(item)"
                  >
                    mdi-minus-circle-outline
                  </v-icon>
                  <span
                    class="text--primary text-bold text-center"
                    v-if="
                      item &&
                        item.selectedAttribute &&
                        addonCartItemNew &&
                        addonCartItemNew[item.addon._id] &&
                        addonCartItemNew[item.addon._id][item.selectedAttribute.id] &&
                        addonCartItemNew[item.addon._id][item.selectedAttribute.id].count
                    "
                    :key="key"
                  >
                    {{ addonCartItemNew[item.addon._id][item.selectedAttribute.id].count || 0 }}
                  </span>
                  <span v-else class="text--primary text-bold text-center">
                    0
                  </span>
                  <v-icon
                    class="primary--text ml-2"
                    size="20"
                    :disabled="disableAddonAttributeOnSelect(item)"
                    @click="selectAddson(item)"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col sm="7" v-if="eventType === 'Reserved' && !isCartHaveValue && isShowChart">
          <!-- <v-row>
            <v-col sm="12" class="d-flex justify-center">
              <v-btn
                class="mx-2 white"
                :disabled="event.purchaseLimit === selectedSeats.length"
                @click="selectBestAvailable()"
              >
                Select Best Available
              </v-btn>
            </v-col>
          </v-row> -->
          <div id="chart"></div>
          <v-row>
            <v-col
              class="subtitle-1 primary--text text-bold cursor-pointer"
              cols="12"
              sm="4"
              v-if="event && event.addson && event.addson.length"
              >Additional Items
              <v-icon v-if="!isShowAdditional" @click="showAdditionalItem()" class="primary--text"
                >mdi-menu-down</v-icon
              >
              <v-icon v-if="isShowAdditional" @click="showAdditionalItem()" class="primary--text"> mdi-menu-up</v-icon>
            </v-col>
            <v-col col="12" sm="12" v-if="isShowAdditional">
              <v-row v-for="(item, index) in event.addson" :key="item.addson">
                <v-col cols="12" class="hidden-sm-and-up">
                  <v-divider />
                </v-col>

                <v-col cols="12" sm="6" md="7" style=" display: -webkit-inline-box;">
                  <v-img
                    style="width: 100px"
                    max-height="100"
                    :src="$getAddon200Image(item.addon._id, item.addon.image)"
                  />
                  <div class="ml-3">
                    <div class="text-bold">
                      {{ item.addon.name }}
                    </div>
                    <div>
                      <div class="addon-descr" v-html="item.addon.description"></div>
                      <div class="pb-1">
                        {{ item.addon.valueName }}
                      </div>
                      <div style="max-width:200px;">
                        <v-select
                          v-model="item.attributes._id"
                          dense
                          outlined
                          :items="getAddonOptions(item)"
                          :item-text="'value'"
                          :item-value="'id'"
                          label="Choose Item"
                          @change="changeAttributeOption(item, index, item.attributes._id)"
                        />
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="3" class="text-center text-sm-right">
                  <div
                    class="text--primary text-bold "
                    v-if="item && item.selectedAttribute && item.selectedAttribute.price"
                  >
                    {{ item.selectedAttribute.price | currency }}
                  </div>
                  <div class="text--primary text-bold " v-else>{{ 0 | currency }}</div>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="px-md-0" v-if="checkTicketQuantity(item)">
                  Sold Out
                </v-col>
                <v-col cols="6" sm="3" md="2" class="px-md-0" v-else-if="checkTicketAvailabe(item)">
                  Unavailable
                </v-col>
                <v-col cols="6" sm="3" md="2" class="px-md-0" v-else>
                  <v-icon
                    class="primary--text mr-2"
                    size="20"
                    :disabled="disableAddonAttributeOnDeselect(item)"
                    @click="deSelectAddson(item)"
                  >
                    mdi-minus-circle-outline
                  </v-icon>
                  <span
                    class="text--primary text-bold text-center"
                    v-if="
                      item &&
                        item.selectedAttribute &&
                        addonCartItemNew &&
                        addonCartItemNew[item.addon._id] &&
                        addonCartItemNew[item.addon._id][item.selectedAttribute.id] &&
                        addonCartItemNew[item.addon._id][item.selectedAttribute.id].count
                    "
                    :key="key"
                  >
                    {{ addonCartItemNew[item.addon._id][item.selectedAttribute.id].count || 0 }}
                  </span>
                  <span v-else class="text--primary text-bold text-center">
                    0
                  </span>
                  <v-icon
                    class="primary--text ml-2"
                    size="20"
                    :disabled="disableAddonAttributeOnSelect(item)"
                    @click="selectAddson(item)"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="outlined-xs-only mt-8 mt-sm-0" cols="12" sm="5">
          <div class="hidden-sm-and-up text-center text-h6 mb-4 text-bold">Your order so far</div>

          <v-row v-for="(item, index) in selectedSeats" :key="index">
            <v-col cols="7" sm="7" lg="8" class="px-md-0">
              <div class="text-bold">
                {{ item.category.name }} <span v-if="item.type === 'Reserved Seating'">({{ item.seat }})</span>
              </div>
            </v-col>

            <v-col cols="5" sm="5" lg="4" class="text-center text-sm-right">
              <div class="text--primary text-bold ">{{ (item.price + item.bookingFee) | currency }}</div>
              <!-- <div>
                <span class="pr-1">+</span>
                <span class="text--primary">{{ item.bookingFee | currency }}</span>
                <span class="pl-1">fees</span>
              </div> -->
            </v-col>

            <v-col cols="12" class="hidden-sm-and-up" v-if="index != selectedSeats.length - 1">
              <v-divider />
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in selectedAddson" :key="index">
            <v-col cols="7" sm="7" lg="8" class="px-md-0">
              <div class="text-bold">{{ item.addon.name }} {{ item.selectedAttribute.value }}</div>
            </v-col>

            <v-col cols="5" sm="5" lg="4" class="text-center text-sm-right">
              <div class="text--primary text-bold ">{{ item.selectedAttribute.price | currency }}</div>
              <!-- <div>
                <span class="pr-1">+</span>
                <span class="text--primary">{{ item.bookingFee | currency }}</span>
                <span class="pl-1">fees</span>
              </div> -->
            </v-col>
            <v-col cols="12" class="hidden-sm-and-up" v-if="index != selectedSeats.length - 1">
              <v-divider />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="7" sm="7" lg="8" class="px-md-0">
              <div>
                <span class="text-bold text-h6">Total </span>
                <span class="px-2">+</span>
                <span class="">FEES</span>
              </div>
            </v-col>
            <v-col cols="5" sm="5" lg="4" class="text-center text-sm-right">
              <div class="text--primary text-bold text-h6">{{ totalPrice() | currency }}</div>
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>
          <v-col cols="12" lg="6" class="ma-auto">
            <v-btn block color="primary" :disabled="selectedSeats.length === 0" @click="palceYourOrder()">
              Book your tickets
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </div>

    <v-divider />

    <div class="pa-md-12">
      <v-row>
        <v-col cols="12" sm="8">
          <v-card-title class="font-weight-bold text-h5">About this Event</v-card-title>
          <v-card-text v-html="event.blurbMain"> </v-card-text>

          <v-card-title v-if="event.blurbExtra" class="font-weight-bold text-h8"
            >Important Event Information</v-card-title
          >
          <v-card-text v-if="event.blurbExtra" v-html="event.blurbExtra"> </v-card-text>

          <v-card-title v-if="event.infoMessage || event.ageRestrictions" class="font-weight-bold text-h8"
            >Age restrictions</v-card-title
          >
          <v-card-text class="font-weight-bold" v-if="event.ageRestrictions"
            >Over {{ event.ageRestrictions }}s only. ID may be required to gain entry. Failure to provide valid ID may
            result in non-admission, without eligibility for a refund.</v-card-text
          >
          <v-card-text v-if="event.infoMessage" v-html="event.infoMessage"> </v-card-text>

          <v-card-title v-if="event.timingInformation" class="font-weight-bold text-h8"
            >Timing Information</v-card-title
          >
          <v-card-text v-if="event.timingInformation" v-html="event.timingInformation"> </v-card-text>

          <v-card-title v-if="event.additionalInformation" class="font-weight-bold text-h8"
            >Event terms & conditions</v-card-title
          >
          <v-card-text v-if="event.additionalInformation" v-html="event.additionalInformation"> </v-card-text>

          <v-card-title v-if="event.venue && event.venue.information" class="font-weight-bold text-h8"
            >Venue Information</v-card-title
          >
          <v-card-text v-if="event.venue && event.venue.information" v-html="event.venue.information"> </v-card-text>

          <v-card-title>Performing:</v-card-title>
          <v-card-subtitle style="padding: 0" v-if="!isPerformerMore">
            <v-card-text>
              <span v-for="(item, index) in event.performers" :key="item._id">
                <span v-if="index < 5"> <span v-if="index != 0">,</span> {{ item.name }}</span></span
              >
              <a
                class="text-h8"
                v-if="event.performers && event.performers.length > 5"
                @click="showMore(event.performers)"
              >
                View More</a
              >
            </v-card-text>
          </v-card-subtitle>
          <v-card-subtitle style="padding: 0" v-if="isPerformerMore">
            <v-card-text>
              <span v-for="(item, index) in event.performers" :key="item._id">
                <span> <span v-if="index != 0">,</span> {{ item.name }}</span></span
              >
              <a class="text-h8" @click="showLess()"> View Less</a>
            </v-card-text>
          </v-card-subtitle>

          <v-card-subtitle class="text-bold text--primary">Ticket Price</v-card-subtitle>
          <template class="pt-8">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Ticket type
                    </th>
                    <th class="text-left">
                      Cost
                    </th>
                    <th class="text-left">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in event.ticketCategories" :key="item._id">
                    <td>{{ item.category.name }}</td>
                    <td>{{ (item.price + item.bookingFee) | currency }}</td>
                    <td>
                      <span v-if="item.availableCount <= item.purchaseCount">Sold Out</span>
                      <span cols="6" sm="3" md="2" class="px-md-0" v-else-if="checkTicketAvailabe(item)">
                        Unavailable
                      </span>
                      <span v-else>
                        Available
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>

          <v-card-subtitle v-if="event.tags && event.tags.lenght > 0" class="text-bold text--primary"
            >Tags:</v-card-subtitle
          >
          <v-card-text>
            <template v-for="tag in event.tags">
              <template v-if="tag">
                <v-chip :key="tag" class="rounded mr-2" color="primary" outlined> {{ tag }} </v-chip>
              </template>
            </template>
          </v-card-text>

          <v-card-subtitle class="text-bold text--primary">Share with friends</v-card-subtitle>
          <v-card-text>
            <share-with-friends :event="event" />
          </v-card-text>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card-title class="text-bold text-h5">Date and Time</v-card-title>
          <v-card-text class="subtitle-2 d-flex flex-column align-start">
            <span>{{ event.startsAt | fullday }}</span>
            <span>{{ event.startsAt | time }} - {{ event.endsAt | time }}</span>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on"><span class="primary--text">Add to calendar</span></a>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in addCalendarOptions" :key="index">
                  <v-list-item-icon class="mr-2">
                    <a
                      ><v-icon color="primary">{{ item.icon }}</v-icon></a
                    >
                  </v-list-item-icon>
                  <v-list-item-title @click="addEventToCalender(item, event)"
                    ><a>{{ item.title }}</a></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-text>
          <v-card-text class="subtitle-2 d-flex flex-column align-start">
            <span>Location:</span>
            <span>{{ event.venue ? event.venue.name : '' }}</span>
            <span v-if="event.venue && event.venue.address && event.venue.address.line1">
              {{ event.venue.address.line1 }}
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.line2">
              {{ event.venue.address.line2 }},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.city">
              {{ event.venue.address.city }},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.postcode">
              {{ event.venue.address.postcode }},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.country">
              {{ event.venue.address.country }}
            </span>
          </v-card-text>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { PromoterEventService, AddonService, TickerBookingService } from '@services';
import _ from 'lodash';
import timer from '@visitor/components/timer';
import ShareWithFriends from './share-with-friends';
import Subscription from './subscription';

export default {
  components: {
    timer,
    ShareWithFriends,
    Subscription,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      selectedSeats: [],
      titcketCount: {},
      stickets: {},
      disableTicketCategory: {},
      disableAddon: {},
      categoryPricing: [],
      eventType: '',
      overlay: false,
      chartHolder: null,
      ticketCategoriesID: [],
      isTicketAvailable: true,
      isPerformerMore: false,
      selectedSeatIOSeats: [],
      maxTicketCategoryCount: [],
      unAvailableCategories: [],
      isTicketSelectFromBestAvailable: false,
      selectBestAvailableSeats: [],
      isCartHaveValue: false,
      isShowChart: false,
      addCalendarOptions: this.addEventCalendarOptions(),
      addsons: [],
      isShowAdditional: true,
      selectedAddson: [],
      key: 0,
      valueName: null,
      labels: [],
      selectedOptions: {},
      selectedAttribute: {},
      addonCartItemNew: {},
      isSeatsioSecondTime: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.getReservationStatus();
    this.$root.$on('emptySeat', async () => {
      this.selectedSeats = [];
      this.addonCartItemNew = {};

      this.selectedAddson = [];
      this.selectedOptions = {};
      this.titcketCount = {};
      if (this.chartHolder) {
        this.chartHolder.clearSelection();
      }
    });
    this.$root.$on('on-timer-end', () => {
      this.selectedSeats = [];
      this.selectedAddson = [];
      this.selectedOptions = {};
      this.addonCartItemNew = {};
      window.localStorage.removeItem('selectedAddson');
      window.localStorage.removeItem('booking_error');
      this.titcketCount = {};
      if (this.chartHolder) {
        this.chartHolder.clearSelection();
      }
      sessionStorage.removeItem('seatsio');
      this.key++;
    });
    this.$bus.$on('onTicketSelectPage', () => {
      this.isShowChart = true;
      this.init();
    });
  },
  beforeDestroy() {
    this.$bus.$off('onTicketSelectPage');
  },
  methods: {
    async init() {
      this.checkOtherEventTicket()
      this.selectedSeats = [];
      this.addonCartItemNew = {};
      window.addEventListener('beforeunload', this.handler);
      let seats = JSON.parse(window.localStorage.getItem('selectedSeats'));
      // seats = (seats || []).filter((s) => s.event === this.event._id);
      if (seats && seats.length) {
        this.selectedSeats = seats;
        this.changeCount();
      }
      let addonItems = JSON.parse(window.localStorage.getItem('selectedAddson'));
      // seats = (seats || []).filter((s) => s.event === this.event._id);
      if (addonItems && addonItems.length) {
        this.selectedAddson = addonItems;
        this.setInitialAddonItemNew();
      }
      let otheseats = (seats || []).filter(s => s.event === this.event._id);
      if ((seats || []).length !== (otheseats || []).length) {
        this.isCartHaveValue = true;
      }
      this.ticketCategoriesID = [];
      this.maxTicketCategoryCount = [];
      this.categoryPricing = [];
      this.event.ticketCategories.forEach(EventTicketCategory => {
        if (!this.checkTicketAvailabe(EventTicketCategory))
          this.ticketCategoriesID.push(EventTicketCategory.category.name);
        this.maxTicketCategoryCount.push({
          category: EventTicketCategory.category.name,
          quantity: EventTicketCategory.purchaseLimit,
        });
        this.categoryPricing.push({
          category: EventTicketCategory.category.name,
          price: (EventTicketCategory.price + EventTicketCategory.bookingFee) / 100,
        });
      });
      if (this.event.ticketCategories && this.event.ticketCategories.length > 0) {
        if (this.event.ticketCategories[0].type === 'Reserved Seating') {
          const selectedSeatIOSeats = JSON.parse(localStorage.getItem('selectedSeatIOSeats'));
          if (selectedSeatIOSeats && selectedSeatIOSeats.length) {
            this.selectedSeatIOSeats = selectedSeatIOSeats;
          }
          if (
            !this.chartHolder &&
            (this.$route.query.page === 'ticketselect' || this.$route.query.page === 'placeorder')
          ) {
            this.isShowChart = true;
            this.prepareChart();
          }
          this.eventType = 'Reserved';
        } else {
          this.eventType = 'UnReserved';
        }
      }
      this.checkTicketCategry();
    },
    showAdditionalItem() {
      this.isShowAdditional = !this.isShowAdditional;
    },
    checkTicketCategry() {
      this.disableTicketCategory = {};
      for (let index = 0; index < this.event.ticketCategories.length; index++) {
        const element = this.event.ticketCategories[index];
        let bucketTickets = 0;
        if (typeof element.bucketTickets === 'object') {
          element.bucketTickets = 0;
          bucketTickets = element.bucketTickets;
        } else {
          bucketTickets = element.bucketTickets;
        }
        if (bucketTickets + element.purchaseCount === element.availableCount) {
          this.disableTicketCategory[element._id] = true;
        }
      }

      if (
        Object.keys(this.disableTicketCategory).length === this.event.ticketCategories.length &&
        this.selectedSeats.length === 0
      ) {
        this.isTicketAvailable = false;
      }
    },
    changeCount() {
      this.titcketCount = {};
      for (let index = 0; index < this.selectedSeats.length; index++) {
        const element = this.selectedSeats[index];
        if (!this.titcketCount[element._id]) {
          this.titcketCount[element._id] = 1;
        } else {
          this.titcketCount[element._id] += 1;
        }
      }
    },
    async handler() {
      localStorage.removeItem('freeTickets');
      document.cookie = 'freeTickets=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    async prepareChart() {
      if (this.selectedSeats && this.selectedSeats.length > 0) {
        this.isSeatsioSecondTime = true;
      }
      this.selectedSeats = [];
      // sessionStorage.removeItem('seatsio');
      this.maxTicketCategoryCount.push({ total: this.event.purchaseLimit });
      this.chartHolder = new window.seatsio.SeatingChart({
        divId: 'chart',
        publicKey: process.env.VUE_APP_SEAT_IO_DESIGNER_KEY,
        event: this.event.seatsIOEventId,
        showMinimap: true,
        availableCategories: this.ticketCategoriesID,
        maxSelectedObjects: this.maxTicketCategoryCount,
        pricing: this.categoryPricing,
        priceFormatter: function(price) {
          return '£' + price;
        },
        // holdOnSelect: true,
        holdOnSelectForGAs: true,
        session: 'continue',
        // regenerateHoldToken: true,
        // holdToken: window.localStorage.getItem('holdToken') || 'wvXbB9MlHt',
        // maxSelectedObjects: this.event.purchaseLimit,
        onObjectSelected: selectedTicket => {
          if (!this.isTicketSelectFromBestAvailable) {
            this.selectReservedSeat(_.cloneDeep(selectedTicket), true);
          }
        },
        onObjectDeselected: deselectedTicket => {
          if (deselectedTicket.status === 'free') {
            this.selectReservedSeat(_.cloneDeep(deselectedTicket), false);
          }
        },
        onBestAvailableSelected: async (array_of_objects, nextToEachOther) => {
          for (let index = 0; index < array_of_objects.length; index++) {
            const element = array_of_objects[index];
            await this.selectReservedSeat(_.cloneDeep(element), true);
          }
          this.isTicketSelectFromBestAvailable = false;
        },
        onBestAvailableSelectionFailed: () => {
          this.isTicketSelectFromBestAvailable = false;
          this.$error('Sorry, the requested seats are not available at a moment.');
        },
        onHoldTokenExpired: async () => {
          sessionStorage.removeItem('seatsio');
          this.$alert({
            title: `Hello, anyone there ?`,
            subtitle: `It seems you've gone inactive on us. Please refresh the page to continue with the ticket booking.`,
            isRefresh: false,
            resolve: async () => {
              window.location.reload();
            },
          });
        },
      }).render();
    },
    async getReservationStatus() {
      const response = await PromoterEventService.getReservationStatus(this.event._id);
      if (response) {
        this.reservationStatus = response;
      }
    },
    async selectReservedSeat(ticket, isTicketSelect) {
      let selectedSeat = ticket.labels.displayedLabel;
      let categoryLabel = `${ticket.category.label}`;
      let time = this.$uuid.v4();
      let currentCategory = _.find(this.event.ticketCategories, category => {
        return category.category.name === categoryLabel;
      });
      if (isTicketSelect && currentCategory) {
        this.overlay = true;
        window.localStorage.setItem('holdToken', this.chartHolder.holdToken);
        const response = await TickerBookingService.addToCart({
          // ticketUniqueId: time,
          user: this.$user._id,
          event: this.event._id,
          eventTicketCategory: currentCategory._id,
          seat: selectedSeat,
        });
        if (response && response.isAllowBooking) {
          if (this.selectedSeats.length === 0 && this.selectedAddson.length === 0 && !this.isSeatsioSecondTime) {
            this.$root.$emit('start-timer');
            await PromoterEventService.changeHoldTokenTime({ token: this.chartHolder.holdToken });
            window.localStorage.setItem('bookedEvent', this.event.slug);
          }
          const selectedSeatItem = _.cloneDeep(currentCategory);
          selectedSeatItem.time = response.ticketUniqueId;
          selectedSeatItem.seat = selectedSeat;
          this.selectedSeats.push(selectedSeatItem);
          window.localStorage.setItem('selectedSeats', JSON.stringify(this.selectedSeats));
          // user activity track start
          this.manageUserActivity({ event: 'add to cart', data: this.selectedSeats });
        }
        this.overlay = false;
      } else if (currentCategory) {
        this.overlay = true;
        const data = [
          {
            id: currentCategory._id,
            bucketTickets: 1,
          },
        ];
        // const seatIndex = this.selectedSeats.findIndex(s => s._id === currentCategory._id);
        const seatIndex = this.selectedSeats.findIndex(s => s.seat === ticket.id);

        if (seatIndex > -1) {
          await TickerBookingService.removeFromCart({
            eventTicketCategoryIds: data,
            times: [this.selectedSeats[seatIndex].time],
          });
          this.selectedSeats.splice(seatIndex, 1);
          window.localStorage.setItem('selectedSeats', JSON.stringify(this.selectedSeats));
          this.manageUserActivity({ event: 'remove from cart', data: this.selectedSeats });
          if (this.selectedSeats.length === 0 && this.selectedAddson.length === 0) {
            this.$root.$emit('stop-timer');
            window.localStorage.removeItem('bookedEvent');
            this.isSeatsioSecondTime = false;
          }
        }
        this.overlay = false;
      }
    },
    // async selectReservedSeat(ticket, isTicketSelect) {
    //   this.overlay = true;
    //   let selectedSeat = ticket.labels.displayedLabel;
    //   let categoryLabel = `${ticket.category.label}`;
    //   let time = this.$uuid.v4();
    //   let currentCategory = _.find(this.event.ticketCategories, category => {
    //     return category.category.name === categoryLabel;
    //   });
    //   if (isTicketSelect && currentCategory) {
    //     this.stickets[currentCategory._id] = this.stickets[currentCategory._id] || [];
    //     this.stickets[currentCategory._id].push(selectedSeat);
    //     window.localStorage.setItem('holdToken', this.chartHolder.holdToken);
    //     const response = await PromoterEventService.createReservation(currentCategory._id, {
    //       seats: this.stickets[currentCategory._id],
    //       time
    //     });
    //     if (response) {
    //       currentCategory['seat'] = selectedSeat;
    //       if (this.selectedSeats.length === 0 && this.selectedAddson.length === 0) {
    //         this.$root.$emit('start-timer');
    //         await PromoterEventService.changeHoldTokenTime({ token: this.chartHolder.holdToken });
    //         window.localStorage.setItem('bookedEvent', this.event.slug);
    //       }
    //       const selectedSeatItem = _.cloneDeep(currentCategory);
    //       selectedSeatItem.time = time;
    //       this.selectedSeats.push(_.cloneDeep(selectedSeatItem));
    //     }
    //   } else if (currentCategory) {
    //     if (this.stickets[currentCategory._id] && this.stickets[currentCategory._id].length) {
    //       let index = _.indexOf(this.stickets[currentCategory._id], selectedSeat);
    //       this.stickets[currentCategory._id].splice(index, 1);
    //     }
    //     const response = await PromoterEventService.createReservation(currentCategory._id, {
    //       seats: this.stickets[currentCategory._id],
    //     });
    //     if (response) {
    //       const seatIndex = this.selectedSeats.findIndex(s => s.seat === ticket.id);
    //       if (seatIndex > -1) {
    //         this.selectedSeats.splice(seatIndex, 1);
    //       }
    //       if (this.selectedSeats.length === 0 && this.selectedAddson.length === 0) {
    //         this.$root.$emit('stop-timer');
    //         window.localStorage.removeItem('bookedEvent');
    //       }
    //     }
    //   }
    //   this.overlay = false;
    //   return;
    // },
    returnBasket() {
      this.$root.$emit('bookingPageChange', 'ticketselect');
      const query = Object.assign({}, this.$route.query);
      query.page = 'ticketselect'
      this.$next({
        name: 'visitor.event',
        params: { id: window.localStorage.getItem('bookedEvent') },
        query: query,
      });
      
      // window.location.reload();
    },
    checkOtherEventTicket() {
      let isCartHaveValue = false;
      let seats = JSON.parse(window.localStorage.getItem('selectedSeats'));
      let otheseats = (seats || []).filter(s => s.event === this.event._id);
      if ((seats || []).length !== (otheseats || []).length) {
        isCartHaveValue = true;
      }
      this.isCartHaveValue = isCartHaveValue;
      console.log("🚀 ~ file: event-select-ticket.vue:922 ~ checkOtherEventTicket ~ isCartHaveValue:", isCartHaveValue)
      return isCartHaveValue;
    },
    async selectSeat(item) {
      if (this.checkOtherEventTicket()) {
        return;
      }
      this.overlay = true;
      // let time = this.$uuid.v4();
      const response = await TickerBookingService.addToCart({
        // ticketUniqueId: time,
        user: this.$user._id,
        event: this.event._id,
        eventTicketCategory: item._id,
      });
      if (response && response.isAllowBooking) {
        if (!this.titcketCount[item._id]) {
          this.titcketCount[item._id] = 0;
        }
        if (response.isSame) {
          this.disableTicketCategory[item._id] = true;
        } else {
          this.disableTicketCategory[item._id] = false;
        }
        this.titcketCount[item._id] = this.titcketCount[item._id] + 1;
        if (this.selectedSeats.length === 0 && this.selectedAddson.length === 0) {
          this.$root.$emit('start-timer');
          window.localStorage.setItem('bookedEvent', this.event.slug);
        }
        const selectedSeatItem = _.cloneDeep(item);
        selectedSeatItem.time = response.ticketUniqueId;
        this.selectedSeats.push(selectedSeatItem);
        window.localStorage.setItem('selectedSeats', JSON.stringify(this.selectedSeats));
        // user activity track start
        this.manageUserActivity({ event: 'add to cart', data: this.selectedSeats });
      } else {
        this.disableTicketCategory[item._id] = true;
      }
      this.overlay = false;
    },
    async selectAddson(item) {
      if (this.checkOtherEventTicket()) {
        return;
      }
      window.localStorage.setItem('eventId', this.event._id);
      let time = this.$uuid.v4();
      this.overlay = true;
      const response = await AddonService.checkCount({
        addonId: item.addon._id,
        eventId: this.event._id,
        time,
        attributeId: item.selectedAttribute.id,
      });
      if (response && response.isAllowBooking) {
        if (response.isSame) {
          this.disableAddon[item.addon._id] = true;
        } else {
          this.disableAddon[item.addon._id] = false;
        }
        if (this.selectedSeats.length === 0 && this.selectedAddson.length === 0) {
          this.$root.$emit('start-timer');
          window.localStorage.setItem('bookedEvent', this.event.slug);
        }
        const seatIndex = this.selectedAddson.findIndex(s => s.addon._id === item.addon._id);
        this.manageAddonAttribute(item, 1, 1);
        const selectedAddonItem = _.cloneDeep(item);
        selectedAddonItem.time = time;
        this.selectedAddson.push(selectedAddonItem);
        window.localStorage.setItem('selectedAddson', JSON.stringify(this.selectedAddson));
        // user activity track start
        this.manageUserActivity({ event: 'add addon to cart', data: this.selectedAddson });
        // user activity track end
        this.key++;
      }
      this.overlay = false;
    },
    async deSelectSeat(item) {
      if (this.checkOtherEventTicket()) {
        return;
      }
      this.overlay = true;
      const data = [
        {
          id: item._id,
          bucketTickets: 1,
        },
      ];
      const seatIndex = this.selectedSeats.findIndex(s => s._id === item._id);
      await TickerBookingService.removeFromCart({
        eventTicketCategoryIds: data,
        times: [this.selectedSeats[seatIndex].time],
      });

      if (seatIndex > -1) {
        this.titcketCount[item._id] = this.titcketCount[item._id] - 1;
        this.selectedSeats.splice(seatIndex, 1);
        this.disableTicketCategory[item._id] = false;
      }
      window.localStorage.setItem('selectedSeats', JSON.stringify(this.selectedSeats));
      this.manageUserActivity({ event: 'remove from cart', data: this.selectedSeats });
      if (this.selectedSeats.length === 0 && this.selectedAddson.length === 0) {
        this.$root.$emit('stop-timer');
        window.localStorage.removeItem('bookedEvent');
      }
      this.overlay = false;
      // // for (let index = this.selectedSeats.length - 1; index >= this.selectedSeats.length; index--) {
      // //   const element = this.selectedSeats[index];
      // //   if (element._id === item._id) {
      // //     seatIndex = index;
      // //     break;
      // //   }
      // // }

      // if (seatIndex > -1) {
      //   this.titcketCount[item._id] = this.titcketCount[item._id] - 1;
      //   await PromoterEventService.releaseBucketTicketByEventTicketCategory({ eventTicketCategoryIds: data, times: [ this.selectedSeats[seatIndex].time] });
      //   await PromoterEventService.createReservation(item._id, { count: this.titcketCount[item._id]});
      //   this.selectedSeats.splice(seatIndex, 1);
      //   this.disableTicketCategory[item._id] = false;
      // } else {
      //   this.titcketCount[item._id] = 0;
      // }
      // window.localStorage.setItem('selectedSeats', JSON.stringify(this.selectedSeats));
      // // user activity track start
      // this.manageUserActivity({event:'remove from cart', data: this.selectedSeats});
      // // user activity track end
      // if (this.selectedSeats.length === 0 && this.selectedAddson.length === 0) {
      //   this.$root.$emit('stop-timer');
      //   window.localStorage.removeItem('bookedEvent');
      // }
      // this.overlay = false;
    },
    async deSelectAddson(currentAddon) {
      if (this.checkOtherEventTicket()) {
        return;
      }
      const item = this.selectedAddson.slice(-1).pop();
      this.overlay = true;
      const addsonsIds = [
        {
          id: currentAddon.addon._id,
          bucketTickets: 1,
          attributeId: currentAddon.selectedAttribute.id,
        },
      ];
      const addonIndex = this.selectedAddson.findIndex(
        s => s.selectedAttribute.id === currentAddon.selectedAttribute.id
      );
      if (addonIndex > -1) {
        await AddonService.releaseTicket({
          addsonsIds,
          event: this.event._id,
          times: [this.selectedAddson[addonIndex].time],
        });
        this.selectedAddson.splice(addonIndex, 1);
        this.disableAddon[currentAddon.addon._id] = false;
      }
      this.manageAddonAttribute(currentAddon, -1, 0);
      if (this.selectedSeats.length === 0 && this.selectedAddson.length === 0) {
        this.$root.$emit('stop-timer');
        window.localStorage.removeItem('bookedEvent');
      }
      window.localStorage.setItem('selectedAddson', JSON.stringify(this.selectedAddson));
      // user activity track start
      this.manageUserActivity({ event: 'remove addon from cart', data: this.selectedAddson });
      // user activity track end
      this.key++;
      this.overlay = false;
    },
    totalPrice() {
      let price = 0;
      for (let index = 0; index < this.selectedSeats.length; index++) {
        const element = this.selectedSeats[index];
        price += element.price + element.bookingFee;
      }
      for (let index = 0; index < this.selectedAddson.length; index++) {
        const element = this.selectedAddson[index];
        price += element.selectedAttribute.price; // + element.bookingFee;
      }
      return price;
    },
    async palceYourOrder() {
      if (!window.Stripe) {
        this.securityConfirm();
        return;
      }
      if (sessionStorage.getItem('seatsio') && this.chartHolder) {
        if (this.chartHolder.selectedObjects && this.chartHolder.selectedObjects.length !== this.selectedSeats.length) {
          this.$error('Incorrect seat selection.');
          return;
        }
      }
      window.localStorage.setItem('selectedSeats', JSON.stringify(this.selectedSeats));
      window.localStorage.setItem('selectedAddson', JSON.stringify(this.selectedAddson));
      this.manageUserActivity({ event: 'book your tickets', data: this.selectedSeats });
      this.logUserActivity();
      // let seats = JSON.parse(window.localStorage.getItem('selectedSeats'));
      this.$root.$emit('seatChange', { seats: this.selectedSeats, addsonItem: this.selectedAddson });
      const query = Object.assign({}, this.$route.query);
      query.page = 'placeorder';
      this.$router.push({
        name: 'visitor.event',
        params: { id: this.$route.params.id },
        query: query,
      });
      this.$root.$emit('bookingPageChange', 'placeorder');
      setTimeout(() => {
        this.$scrollToElement('place-order');
      }, 200);
    },
    selectBestAvailable() {
      this.$ticket({
        limit: this.event.purchaseLimit - this.selectedSeats.length,
        resolve: async data => {
          this.isTicketSelectFromBestAvailable = true;
          this.chartHolder.selectBestAvailable({
            number: Number(data),
            clearSelection: false, // select the {response} best available seats in addition to current selection
          });
        },
      });
    },
    showMore() {
      this.isPerformerMore = true;
    },
    showLess() {
      this.isPerformerMore = false;
    },
    checkTicketAvailabe(ticket) {
      let isAvailableError = false;
      if (
        ticket.availableFrom &&
        ticket.availableUntil &&
        !moment().isBetween(moment(ticket.availableFrom), moment(ticket.availableUntil), 'days', true)
      ) {
        isAvailableError = true;
      } else if (ticket.availableFrom && moment().isBefore(moment(ticket.availableFrom))) {
        isAvailableError = true;
      } else if (ticket.availableUntil && moment().isAfter(moment(ticket.availableUntil))) {
        isAvailableError = true;
      }
      if (this.isShowUnavailablePage) {
        isAvailableError = true;
      }
      return isAvailableError;
    },
    disableAddonAttributeOnSelect(item) {
      let disabledFlag = false;
      let addonId = item.addon._id;
      if (item && item.selectedAttribute) {
        let attrId = item.selectedAttribute.id;
        let attrCnt =
          this.addonCartItemNew &&
          this.addonCartItemNew[addonId] &&
          this.addonCartItemNew[addonId][attrId] &&
          this.addonCartItemNew[addonId][attrId].count
            ? this.addonCartItemNew[addonId][attrId].count
            : 0;
        if (attrCnt === item.selectedAttribute.quantity) {
          disabledFlag = true;
        }
      } else {
        disabledFlag = true;
      }
      return disabledFlag;
    },
    disableAddonAttributeOnDeselect(item) {
      let addonId = item.addon._id;
      let disabledFlag = false;
      if (item && item.selectedAttribute) {
        let attrId = item.selectedAttribute.id;
        let attrCnt =
          this.addonCartItemNew &&
          this.addonCartItemNew[addonId] &&
          this.addonCartItemNew[addonId][attrId] &&
          this.addonCartItemNew[addonId][attrId].count
            ? this.addonCartItemNew[addonId][attrId].count
            : 0;
        if (attrCnt === 0) {
          disabledFlag = true;
        }
      } else {
        disabledFlag = true;
      }
      return disabledFlag;
    },
    setInitialAddonItemNew() {
      this.addonCartItemNew = {};
      for (let index = 0; index < this.selectedAddson.length; index++) {
        const element = this.selectedAddson[index];
        let addonId = element.addon._id;
        let attrId = element.selectedAttribute.id;
        if (this.addonCartItemNew[addonId]) {
          if (this.addonCartItemNew[addonId][attrId]) {
            this.addonCartItemNew[addonId][attrId] = {
              count: this.addonCartItemNew[addonId][attrId].count + 1,
              selAttr: attrId,
            };
          } else {
            this.addonCartItemNew[addonId][attrId] = {
              count: 1,
              selAttr: attrId,
            };
          }
        } else {
          this.addonCartItemNew[addonId] = [];
          this.addonCartItemNew[addonId][attrId] = {
            count: 1,
            selAttr: attrId,
          };
        }
      }
    },
    changeAttributeOption(addon, addonIndex, selectedAddonAttrId) {
      let attrIndex = addon.attributes.findIndex(s => s.id === selectedAddonAttrId);
      let addonAttribute = _.find(addon.attributes, addonAttribute => {
        return addonAttribute.id === selectedAddonAttrId;
      });
      this.overlay = true;
      this.event.addson[addonIndex].selectedAttribute = addonAttribute;
      this.selectedAttribute = addonAttribute;
      this.overlay = false;
    },
    manageAddonAttribute(item, selectedQty, initalQty) {
      if (this.addonCartItemNew[item.addon._id]) {
        if (this.addonCartItemNew[item.addon._id][item.selectedAttribute.id]) {
          this.addonCartItemNew[item.addon._id][item.selectedAttribute.id] = {
            count: this.addonCartItemNew[item.addon._id][item.selectedAttribute.id].count + selectedQty,
            selAttr: item.selectedAttribute.id,
          };
        } else {
          this.addonCartItemNew[item.addon._id][item.selectedAttribute.id] = {
            count: initalQty,
            selAttr: item.selectedAttribute.id,
          };
        }
      } else {
        this.addonCartItemNew[item.addon._id] = [];
        this.addonCartItemNew[item.addon._id][item.selectedAttribute.id] = {
          count: initalQty,
          selAttr: item.selectedAttribute.id,
        };
      }
    },
    checkTicketQuantity(item) {
      if (item && item.selectedAttribute) {
        if (item.selectedAttribute.quantity <= item.selectedAttribute.purchaseCount) {
          return true;
        }
      }
      return false;
    },
    getAddonOptions(item) {
      return item.attributes;
    },
  },
};
</script>
<style>
.addon-descr p {
  max-width: 350px;
  max-height: 150px;
  overflow-y: auto;
}

@media only screen and (max-width: 599px) {
  .addon-descr p {
    max-width: 200px;
    max-height: 150px;
    overflow-y: auto;
  }
}
</style>
