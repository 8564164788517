<template>
  <div>
    <v-row class="ma-0" justify="end">
      <v-col cols="12" sm="4" md="3">
        <v-btn block color="primary" @click="$root.$emit('visitor-login')">Click to book tickets</v-btn>
      </v-col>
    </v-row>

    <v-divider />

    <div class="pa-md-12">
      <v-row>
        <v-col cols="12" sm="8">
          <v-card-title class="font-weight-bold text-h5">About this Event</v-card-title>
          <v-card-text v-html="event.blurbMain"> </v-card-text>

          <v-card-title v-if="event.blurbExtra" class="font-weight-bold text-h8">Important Event Information</v-card-title>
          <v-card-text v-if="event.blurbExtra" v-html="event.blurbExtra"> </v-card-text>

          <v-card-title v-if="event.infoMessage || event.ageRestrictions" class="font-weight-bold text-h8">Age restrictions</v-card-title>
          <v-card-text class="font-weight-bold" v-if="event.ageRestrictions">Over {{event.ageRestrictions}}s only. ID may be required to gain entry. Failure to provide valid ID may result in non-admission, without eligibility for a refund.</v-card-text>
          <v-card-text v-if="event.infoMessage" v-html="event.infoMessage"> </v-card-text>

          <v-card-title v-if="event.timingInformation" class="font-weight-bold text-h8">Timing Information</v-card-title>
          <v-card-text v-if="event.timingInformation" v-html="event.timingInformation"> </v-card-text>

          <v-card-title v-if="event.additionalInformation" class="font-weight-bold text-h8">Event terms & conditions</v-card-title>
          <v-card-text v-if="event.additionalInformation" v-html="event.additionalInformation"> </v-card-text>

          <v-card-title v-if="event.venue && event.venue.information" class="font-weight-bold text-h8">Venue Information</v-card-title>
          <v-card-text v-if="event.venue && event.venue.information" v-html="event.venue.information"> </v-card-text>

          <v-card-title>Performing:</v-card-title>
          <v-card-subtitle style="padding: 0" v-if="!isPerformerMore">
            <v-card-text>
              <span v-for="(item, index) in event.performers" :key="item._id">
                <span v-if="index < 5"> <span v-if="index != 0">,</span> {{ item.name }}</span></span
              >
              <a
                class="text-h8"
                v-if="event.performers && event.performers.length > 5"
                @click="showMore(event.performers)"
              >
                View More</a
              >
            </v-card-text>
          </v-card-subtitle>
          <v-card-subtitle style="padding: 0" v-if="isPerformerMore">
            <v-card-text>
              <span v-for="(item, index) in event.performers" :key="item._id">
                <span> <span v-if="index != 0">,</span> {{ item.name }}</span></span
              >
              <a
                class="text-h8"
                @click="showLess()"
              >
                View Less</a
              >
            </v-card-text>
          </v-card-subtitle>

          <v-card-subtitle class="text-bold text--primary">Ticket Price</v-card-subtitle>
          <template class="pt-8">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Ticket type
                    </th>
                    <th class="text-left">
                      Cost
                    </th>
                    <th class="text-left">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in event.ticketCategories" :key="item._id">
                    <td>{{ item.category.name }}</td>
                    <td>{{ (item.price + item.bookingFee) | currency }}</td>
                    <td>
                      <span v-if="item.availableCount <= item.purchaseCount">Sold Out</span>
                      <span cols="6" sm="3" md="2" class="px-md-0" v-else-if="checkTicketAvailabe(item)">
                        Unavailable
                      </span>
                      <span v-else>
                        Available
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>

          <v-card-subtitle v-if="event.tags && event.tags.length > 0" class="text-bold text--primary"
            >Tags:</v-card-subtitle
          >
          <v-card-text>
            <template v-for="tag in event.tags">
              <template v-if="tag">
                <v-chip :key="tag" class="rounded mr-2" color="primary" outlined> {{ tag }} </v-chip>
              </template>
            </template>
          </v-card-text>

          <v-card-subtitle class="text-bold text--primary">Share with friends</v-card-subtitle>
          <v-card-text>
            <share-with-friends :event="event" />
          </v-card-text>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card-title class="text-bold text-h5">Date and Time</v-card-title>
          <v-card-text class="subtitle-2 d-flex flex-column align-start">
            <span>{{ event.startsAt | fullday }}</span>
            <span>{{ event.startsAt | time }} - {{ event.endsAt | time }}</span>
          </v-card-text>
          <v-card-text class="subtitle-2 d-flex flex-column align-start">
            <span>Location:</span>
            <span>{{ event.venue ? event.venue.name : '' }}</span>
          </v-card-text>

          <v-card-title v-if="isShowUnavailablePage" class="text-bold text-h5"> Ticket Available Date</v-card-title>
          <v-card-text v-if="isShowUnavailablePage" class="subtitle-2 d-flex flex-column align-start">
            <span>Start Date - {{ event.availableFrom | fullDateAndTime }}</span>
            <span>End Date - {{ event.availableUntil | fullDateAndTime }}</span>
          </v-card-text>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ShareWithFriends from './share-with-friends';

export default {
  data() {
    return {
      isShowUnavailablePage: false,
      isPerformerMore: false,
    };
  },
  components: {
    ShareWithFriends,
  },
  props: {
    event: Object,
  },
  methods: {
    showMore() {
      this.isPerformerMore = true;
    },
    showLess() {
      this.isPerformerMore = false;
    },
    checkTicketAvailabe(ticket) {
      let isAvailableError = false;
      if (
        ticket.availableFrom &&
        ticket.availableUntil &&
        !moment().isBetween(moment(ticket.availableFrom), moment(ticket.availableUntil), 'days', true)
      ) {
        isAvailableError = true;
      } else if (ticket.availableFrom && moment().isBefore(moment(ticket.availableFrom))) {
        isAvailableError = true;
      } else if (ticket.availableUntil && moment().isAfter(moment(ticket.availableUntil))) {
        isAvailableError = true;
      }
      if (this.isShowUnavailablePage) {
         isAvailableError = true;
      }
      return isAvailableError;
    },
  }
};
</script>
