<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-card class="mb-4 pa-4 rounded-0" elevation="0" outlined>
        <v-card-title class="font-weight-bold">Email Preferences</v-card-title>
        <v-card-text class="pb-0">
          <v-card-title class="body-2 text--primary pa-0 pb-4">Email Me</v-card-title>
          <v-checkbox @change="save()" v-model="data.unSubscribeNews">
            <template v-slot:label>
              <div class="body-2 text--primary">
                Unsubscribe from all Ticket Text newsletters and updates for attendees
              </div>
            </template>
          </v-checkbox>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12">
      <v-card class="mb-12 pa-4 rounded-0" elevation="0" outlined>
        <v-card-title class="font-weight-bold">Delete my account</v-card-title>
        <v-card-text>
          <v-alert color="red" text class="mb-8">
            <span class="text--primary body-1">
              Please note if you choose to delete your account, it will be permanently deleted and unrecoverable.
              <br />
              If you change your mind in the future, you will need to sign-up again and create a new account.
            </span>
          </v-alert>
          <v-checkbox v-model="chk6" v-if="false">
            <template v-slot:label>
              <div class="body-2 text--primary">Delete my stored data</div>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn color="primary" @click="deleteAccount()" class="px-8">Delete my account</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { UserService } from '@services';
export default {
  data() {
    return {
      valid: false,
      data: {},
      chk6: false,
      image: '',
      loading: {
        upload: false,
        save: false,
        remove: false,
      },
    };
  },
  created() {
    this.getCurrentUser();
  },
  methods: {
    async getCurrentUser() {
      this.data = await UserService.me();
      this.$store.set('auth/user', this.data);
    },
    async save() {
      this.$confirm({
        title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this document?`,
        resolve: async () => {
          this.loading.save = true;
          await UserService.update(this.data._id, this.data);
          this.loading.save = false;
        },
      });
    },
    async deleteAccount() {
      this.$confirm({
        title: `Are you sure you want to delete this user?`,
        resolve: async () => {
          const response = await UserService.deleteUser(this.data._id);
          if (response) {
            this.$logout();
          }
        },
      });
    },
  },
  // watch: {
  //   'data.unSubscribeNews': function() {
  //     this.save();
  //   },
  // },
};
</script>
