<template>
  <span>
    <template v-for="icon in icons">
      <ShareNetwork
        :url="getUrl(icon)"
        :key="icon"
        :network="icon"
        :title="getTitle()"
        twitter-user="tkttxt"
        :quote="getTitle()"
        hashtags="tickettext"
        class="text-decoration-none"
      >
        <v-btn icon color="primary">
          <v-icon> {{ `mdi-${icon}` }}</v-icon>
        </v-btn>
      </ShareNetwork>
    </template>
    <v-btn icon color="red" @click="$copy(getEventUrl())">
      <v-icon>mdi-content-copy</v-icon>
    </v-btn>
  </span>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    event: Object,
  },
  data() {
    return {
      icons: ['facebook', 'twitter'],
    };
  },
  methods: {
    getUrl(key) {
      return `${window.location.origin}${window.location.pathname}?utm_source=${key}&utm_medium=social&utm_campaign=tickettext`;
    },
    getEventUrl() {
      let url = `${window.location.protocol}//${window.location.host}`;
      // const port = window.location.port;
      // if (port) url += `:${port}`;
      return `${url}/${this.event.slug}?utm_source=link&utm_medium=social&utm_campaign=tickettext`;
    },
    getTitle() {
      let title = '';

      if (this.event.page == 'eventbooked') {
        title = `I've just bought a ticket`;
      } else {
        title = `I am planning to attend an event`;
      }

      if (this.event.performers && this.event.performers[0]?.name) {
        title += ` to see ${this.event.performers[0].name}`;
      }

      title += ` on ${moment(this.event.startsAt).format('MMMM Do')}`;

      if (this.event.venue?.name) {
        title += ` at ${this.event.venue.name}.`;
      }

      return title;
    },
  },
};
</script>
