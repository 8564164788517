<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-card outlined>
        <v-card-title class="primary--text font-weight-bold">
          Your Details
          <v-spacer />
          <v-list-item-avatar size="80">
            <v-img v-if="user.image" :src="$getProfile200Image(user._id, user.image)" />
            <v-img v-if="!user.image" src="@/assets/images/promotor.svg" />
          </v-list-item-avatar>
        </v-card-title>

        <v-card-text>
          <v-list dense>
            <v-list-item>
              <v-list-item-title class="d-flex">
                <div class="w-50 text-bold">First name</div>
                <div>{{ user.firstName }}</div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="d-flex">
                <div class="w-50 text-bold">Last name</div>
                <div>{{ user.lastName }}</div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="d-flex">
                <div class="w-50 text-bold">Email</div>
                <div>{{ user.email }}</div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="d-flex">
                <div class="w-50 text-bold">Phone</div>
                <div>{{ user.phone }}</div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn color="primary" class="px-8" :to="{ name: 'visitor.account.edit', params: { tab: 0 } }">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card  outlined>
        <v-card-title class="primary--text font-weight-bold">
          Address
          <!-- <v-spacer />
                      <v-btn plain small class="primary--text pa-0">Add new address</v-btn> -->
        </v-card-title>

        <v-card-text>
          <v-list dense class="pa-0">
            <v-list-item>
              <v-list-item-title class="d-flex">
                <div class="w-50 text-bold">Address</div>
                <div>{{ user.address }}</div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="d-flex">
                <div class="w-50 text-bold">City</div>
                <div>{{ user.city }}</div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="d-flex">
                <div class="w-50 text-bold">State/County</div>
                <div>{{ user.state }}</div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="d-flex">
                <div class="w-50 text-bold">Postcode</div>
                <div>{{ user.postcode }}</div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title class="d-flex">
                <div class="w-50 text-bold">Country</div>
                <div>{{ user.country }}</div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions >
          <v-spacer />
          <v-btn color="primary" class="px-8" :to="{ name: 'visitor.account.edit', params: { tab: 0 } }">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <!-- <v-col cols="12" sm="6">
      <v-card class="mb-4 pa-4 rounded-0" elevation="0" outlined>
        <v-card-title class="primary--text font-weight-bold"> Your payment method </v-card-title>

        <v-card-text>
          <v-list-item class="pa-0">
            <v-list-item-title>
              <v-list-item-content>
                <v-list-item-subtitle class="body-2 text--primary">
                  <span>You have no saved payment methods, please add one below</span><br />
                  <span>Add your payment card details to spend up the checkout process.</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item-title>
          </v-list-item>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn color="primary" class="px-8" >Add Payment method</v-btn>
        </v-card-actions>
      </v-card>
    </v-col> -->
  </v-row>
</template>

<script>
export default {
  props: {
    user: Object
  }
}
</script>
