<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card-title class="px-12 py-8 justify-space-between" id="place-order">
      <span class="subtitle-1 text-bold">Place your order</span>
      <span class="subtitle-2 d-flex">Time left: <timer iscarthide="hide" class="ml-2"/></span>
      <span class="subtitle-1 text-bold text-decoration-underline" style="cursor:pointer" @click="back()"
        >Back to event page</span
      >
    </v-card-title>

    <v-divider />

    <div class="pa-4 pa-md-12">
      <v-row>
        <v-col cols="12" sm="7">
          <v-list-item two-line class="pa-0 justify-space-between">
            <v-list-item-content>
              <v-list-item-title class="body-1 text-bold"
                >{{ event.title }}
                <span v-if="event.performers && event.performers.length > 0">
                  with {{ event.performers[0].name }}</span
                ></v-list-item-title
              >
              <v-list-item-subtitle class="body-2 font-weight-semibold">
                {{ event.startsAt | dateWithDay }} - {{ event.endsAt | time }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="5">
          <v-list-item two-line class="pa-0" v-for="(item, index) in selectedSeats" :key="index">
            <v-list-item-content class="body-2 text-bold align-self-start" style="flex: 300px 0">
              <v-list-item-title
                >{{ item.category.name }}
                <span v-if="item.type === 'Reserved Seating'">({{ item.seat }})</span></v-list-item-title
              >
            </v-list-item-content>

            <v-list-item-content class="body-2 align-self-start ml-auto" style="flex: 120px 0">
              <v-list-item-title class="text-bold d-flex align-baseline justify-end">
                <span class="text--primary">{{ (item.price + item.bookingFee) | currency }}</span>
              </v-list-item-title>
              <!-- <v-list-item-subtitle class="font-weight-semibold d-flex align-baseline justify-end">
                <v-icon aria-hidden="false" class="text--primary pa-0" size="14"> mdi-plus </v-icon>
                <span class="text--primary">{{ item.bookingFee | currency }}</span>
                <span class="pl-1">fees</span>
              </v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line class="pa-0" v-for="(item, index) in selectedAddson" :key="index">
            <v-list-item-content class="body-2 text-bold align-self-start" style="flex: 300px 0">
              <v-list-item-title>{{ item.addon.name }} {{ item.selectedAttribute.value }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-content class="body-2 align-self-start ml-auto" style="flex: 120px 0">
              <v-list-item-title class="text-bold d-flex align-baseline justify-end">
                <span class="text--primary">{{ item.selectedAttribute.price | currency }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <p>
            <a><span class="primary--text" @click="changeSelecion()">Change Selection</span></a> or
            <a><span class="primary--text" @click="emptyBasket()">empty basket</span></a>
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4" v-if="!isFreeBooking && actualTicketPrice !== 0">
          <v-text-field
            outlined
            dense
            v-model="promocode"
            required
            :disabled="isPromocodeApplied"
            hide-details
            name="promocode"
            label="Promocode"
            placeholder="e.g. FLATOFF"
          />
        </v-col>
        <v-col v-if="!isFreeBooking && actualTicketPrice !== 0 && !isPromocodeApplied" cols="12" sm="2">
          <v-btn
            block
            color="primary"
            :loading="promocodeLoading"
            :disabled="isValidPromocode"
            @click="applyPromocode()"
            >Apply</v-btn
          >
        </v-col>
        <v-col v-if="isPromocodeApplied" cols="12" sm="2">
          <v-btn block color="primary" :loading="promocodeLoading" @click="clearPromocode()">Remove</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="green--text mt--2 offset-md-6 mt-n3 " v-if="isPromocodeApplied">
          You have applied the <span class="font-weight-bold">"{{ currentPromocode }}"</span> promocode
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-divider />
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
            type="email"
            outlined
            dense
            v-model="promocode"
            clearable
            required
            name="promocode"
            label="Promocode"
            placeholder="e.g. FLATOFF"
          />
        </v-col>
        <v-col cols="12" sm="1">
          <v-btn color="primary" @click="applyPromocode()">Apply</v-btn>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col cols="12" sm="12">
          <v-list-item class="pa-0">
            <v-list-item-content style="flex: 300px 0">
              <v-list-item-title class="d-flex align-center">
                <span class="text-h5 text-bold">Total</span>
                <v-icon aria-hidden="false" class="text--primary pa-0" size="14"> mdi-plus </v-icon>
                <small>FEES</small>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content class="align-self-start ml-auto" style="flex: 120px 0">
              <v-list-item-title class="text-h5 text-bold d-flex align-baseline justify-end">
                <!-- <v-icon aria-hidden="false" class="text--primary pa-0" size="24"> mdi-currency-gbp </v-icon> -->
                <span class="text--primary">{{ price | currency }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-list-item two-line class="pa-0 pt-8 justify-space-between">
            <v-list-item-content>
              <v-list-item-title class="body-1 text-bold">Contact Information</v-list-item-title>
              <!-- <v-list-item-subtitle class="body-2 font-weight-semibold">
                Continue as guest or login for a faster experience.
              </v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="mt-0">
          <v-col cols="12" sm="4">
            <v-select
              v-model="data.title"
              :items="titles"
              label="Title"
              dense
              clearable
              :rules="[v => !!v || 'Title is required']"
              @click:clear="clear('title')"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              type="text"
              outlined
              dense
              clearable
              v-model="data.firstName"
              required
              name="firstName"
              :rules="[
                v => (!!v && v && !!v.trim()) || 'First Name is required',
                v => (v && v.length < 20) || 'First name must less than 20 character',
              ]"
              label="First Name"
              placeholder="John Doe"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              type="text"
              outlined
              dense
              clearable
              v-model="data.lastName"
              required
              name="lastName"
              :rules="[
                v => (!!v && v && !!v.trim()) || 'Last Name is required',
                v => (v && v.length < 20) || 'Last name must less than 20 character',
              ]"
              label="Last Name"
              placeholder="John Doe"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="6">
            <v-text-field
              type="email"
              outlined
              dense
              clearable
              required
              v-model="data.email"
              :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
              name="email"
              label="Email"
              placeholder="johndoe@gmail.com"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              v-model="data.billing_phone_number"
              clearable
              required
              name="phone"
              :rules="[v => !!v || 'Phone is required', v => (v && v.length == 11) || 'Phone number must 11 digit']"
              label="Phone"
              placeholder="123 456 7890"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row v-if="!isFreeBooking && price != 0">
        <v-col cols="12" sm="12">
          <v-list-item two-line class="pa-0 justify-space-between">
            <v-list-item-content>
              <v-list-item-title class="body-1 mb-2 text-bold">Payment</v-list-item-title>
              <v-list-item-subtitle class="body-2 font-weight-semibold"> Choose a payment method </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>

      <!-- <v-row class="mt-0">
        <v-col cols="12" sm="12">
          <v-radio-group v-model="radios" row>
            <v-radio value="Card" class="ma-0 v-sheet--outlined rounded px-8 py-4" style="flex: 48% 0">
              <template v-slot:label>
                <div class="d-flex align-center w-100">
                  <span class="pl-2">Credit or Debit Card</span>
                  <v-icon aria-hidden="false" class="ml-auto"> mdi-credit-card-outline </v-icon>
                </div>
              </template>
            </v-radio>
            <v-radio value="PayPal" class="ma-0 ml-auto v-sheet--outlined rounded px-8 py-4" style="flex: 48% 0">
              <template v-slot:label>
                <div class="d-flex align-center w-100">
                  <span class="pl-2">PayPal</span>
                  <v-icon aria-hidden="false" class="ml-auto"> mdi-contactless-payment-circle-outline </v-icon>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row> -->

      <v-row class="stripe-section" v-show="radios === 'Card' && !isFreeBooking && price != 0">
        <v-col cols="12" sm="6">
          <v-card class="v-sheet--outlined rounded px-8 py-4">
            <div id="card-element">
              <!-- A Stripe Element will be inserted here. -->
            </div>
            <!-- Used to display form errors. -->
            <div id="card-errors" role="alert"></div>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row v-if="radios === 'PayPal'">
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12" sm="12">
              <div class="text--primary subtitle-2">Sign into your PayPal account to complete your purchase</div>
            </v-col>
            <v-col cols="12" sm="12">
              <v-btn depressed rounded color="indigo" class="w-100">
                <span class="white--text">Pay with PayPal</span>
                <v-icon right class="white--text"> mdi-contactless-payment-circle-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col cols="12" sm="12">
          <v-list-item two-line class="pa-0  justify-space-between">
            <v-list-item-content>
              <v-list-item-title class="body-1 text-bold">Billing Address</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-form ref="form2" v-model="valid" lazy-validation>
        <v-row class="mt-0">
          <v-col cols="12" sm="4">
            <v-text-field
              type="text"
              outlined
              dense
              clearable
              v-model="data.billing_address_line1"
              required
              name="line1"
              :rules="[v => (!!v && v && !!v.trim()) || 'Address line 1 is required']"
              label="Address line 1"
              placeholder="address line 1"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              type="text"
              outlined
              dense
              clearable
              v-model="data.billing_address_line2"
              required
              name="line2"
              label="Address line 2"
              placeholder="address line 2"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              clearable
              required
              v-model="data.billing_address_city"
              :rules="[v => (!!v && v && !!v.trim()) || 'Town/city is required']"
              name="city"
              label="Town/city"
              placeholder="city"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="4">
            <v-text-field
              type="text"
              outlined
              dense
              clearable
              v-model="data.county"
              required
              name="county"
              label="County"
              placeholder="John Doe"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              type="text"
              outlined
              dense
              clearable
              v-model="data.billing_address_zip"
              required
              name="Code"
              :rules="[v => (!!v && v && !!v.trim()) || 'Postal Code is required']"
              label="Postal Code"
              placeholder="136 450"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              clearable
              required
              v-model="data.country"
              :rules="[v => (!!v && v && !!v.trim()) || 'Country is required']"
              name="email"
              label="Country"
              placeholder="England"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-form ref="form3" v-model="valid" lazy-validation>
        <v-row v-if="event.survey.length" v-for="(survey, surveyIndex) in event.survey" :key="surveyIndex">
          <span>
            <v-col cols="12" sm="12" class="pb-0">
              <v-list-item two-line class="pa-0 justify-space-between">
                <v-list-item-content>
                  <v-list-item-title class="body-1 text-bold">{{ survey.name }}</v-list-item-title>
                  <p class="pt-2 mb-0">{{ survey.description }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              class="pb-0 pt-1"
              v-for="(question, questionIndex) in survey.questions"
              :key="questionIndex"
            >
              <template v-if="question.questionType === 'radio'">
                <p class="mb-1">{{ question.question }} <span v-if="question.isRequired" class="red--text"><strong>* </strong></span></p>
                <v-radio-group v-model="question.answer" :rules="question.isRequired ? [v => !!`${v}` || 'Question is required'] : []"
                  :required="!question.isRequired">
                  <v-radio
                    v-for="option in question.options"
                    :key="option.answer"
                    :label="option.answer"
                    :value="option.answer"
                    
                  ></v-radio>
                </v-radio-group>
              </template>
              <template v-if="question.questionType === 'select'">
                <p class="mb-1">{{ question.question }} <span v-if="question.isRequired" class="red--text"><strong>* </strong></span></p>
                <v-select
                  outlined
                  dense
                  item-text="answer"
                  item-value="answer"
                  clearable
                  :items="question.options"
                  v-model="question.answer"
                  placeholder="Your answer"
                  :rules="question.isRequired ? [v => !!`${v}` || 'Question is required'] : []"
                  :required="!question.isRequired"
                >
                  <!-- <template v-slot:item="data">
                  <span>{{ data.item.answer }}</span>
                </template> -->
                </v-select>
              </template>

              <template v-if="question.questionType === 'textbox'">
                <p class="mb-1">{{ question.question }} <span v-if="question.isRequired" class="red--text"><strong>* </strong></span></p>
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="question.answer"
                  name="email"
                  placeholder="Your answer"
                  :rules="question.isRequired ? [v => !!`${v}` || 'Question is required'] : []"
                  :required="!question.isRequired"
                />
              </template>

              <template v-if="question.questionType === 'checkbox'">
                <p class="mb-1">{{ question.question }} <span v-if="question.isRequired" class="red--text"><strong>* </strong></span></p>
                <template v-for="(option, index) in question.options">
                  <v-checkbox
                    name="index"
                    v-model="selected"
                    :label="option.answer"
                    :value="option.answer"
                    @change="checkAnswer(questionIndex, surveyIndex)"
                    :rules="question.isRequired ? [v => question.answer.length > 0 || 'At least one answer should be selected' ] : []"
                    :required="!question.isRequired"
                  >
                  </v-checkbox>
                </template>
              </template>
            </v-col>
          </span>

        </v-row>
      </v-form>

      <v-row>
        <v-col cols="12" sm="12">
          <v-checkbox v-model="send_marketing_emails">
            <template v-slot:label>
              <div class="text--primary subtitle-2">
                Keep me updated on the latest news, events, and exclusive offers from this event organizer
              </div>
            </template>
          </v-checkbox>
          <v-checkbox v-model="can_send_mail">
            <template v-slot:label>
              <div class="text--primary subtitle-2">
                Ticket Text can send me emails about the best events happening nearby.
              </div>
            </template>
          </v-checkbox>
          <v-row
            v-if="event.infoMessage || event.timingInformation || event.additionalInformation || event.ageRestrictions"
          >
            <v-col cols="12" sm="12">
              <v-checkbox v-model="is_age_information">
                <template v-slot:label>
                  <div class="text--primary subtitle-2">
                    I confirm that I have read and understood any age restriction info and terms and conditions for this
                    event.
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" class="pt-0">
          <small class="caption text--primary">
            By clicking "Place Order", I accept the
            <a href="/terms" target="__blank"><span class="primary--text">Terms of Service</span></a>
            and have read the
            <a href="/privacy" target="__blank"><span class="primary--text">Privacy Policy.</span></a>
            <br />
            I agree that Ticket Text may share my information with the event organizer.
          </small>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <vue-recaptcha @verify="onVerify" :sitekey="captchaKey" :loadRecaptchaScript="true"></vue-recaptcha>
        </v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="12" sm="4" md="3">
          <v-btn block outlined color="primary" @click="back()"> Back </v-btn>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <v-btn block color="primary" :disabled="isSumitted" :loading="loading" class="ml-auto" @click="payOut()">
            Place your order
          </v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" width="800px" @click:outside="cancel()">
        <v-card>
          <v-card-title class="primary--text">
            Remove Promocode
          </v-card-title>

          <v-card-text>
            Are you sure to remove the promocode?
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn width="100" outlined dense color="primary" @click="cancel()">Cancel</v-btn>
            <v-btn width="100" color="primary" :loading="loading" @click="removePromocode()"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { PromoterEventService, PromocodeService, PaymentService, AddonService, TickerBookingService } from '@services';
import timer from '@visitor/components/timer';
import VueRecaptcha from 'vue-recaptcha';

let card;
const style = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};
export default {
  components: {
    timer,
    VueRecaptcha,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      radios: 'Card',
      data: {},
      send_marketing_emails: false,
      valid: true,
      isSumitted: false,
      selectedSeats: [],
      selectedAddson: [],
      titles: ['Dr', 'Master', 'Miss', 'Mr', 'Mrs', 'Ms', 'Mx'],
      ticketsIDs: [],
      promocode: null,
      stripe: null,
      loading: false,
      isValidPromocode: false,
      is_age_information: false,
      can_send_mail: false,
      price: 0,
      isFreeBooking: false,
      isCaptchaChecked: false,
      captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
      dialog: false,
      isPromocodeApplied: false,
      currentPromocode: '',
      actualTicketPrice: 0,
      overlay: false,
      promocodeLoading: false,
      selected: [],
    };
  },
  async mounted() {
    if (!window.Stripe) {
      await this.$loadScript('https://js.stripe.com/v3/');
    }

    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_API_KEY);
    if (this.stripe) {
      this.createElement();
    }

    if (this.$user) {
      this.data = _.cloneDeep(this.$user);
      this.data.billing_phone_number = this.$user.phone;
      this.data.billing_address_line1 = this.$user.address;
      this.data.billing_address_city = this.$user.city;
      this.data.billing_address_zip = this.$user.postcode;
      this.data.billing_address_country = this.$user.country;
      this.data.county = this.$user.county;
    }

    const cookieValue = localStorage.getItem('freeTickets'); // this.readCookie('freeTickets');
    if (cookieValue === this.event._id) {
      this.isFreeBooking = true;
    }

    this.$root.$on('seatChange', options => {
      this.selectedSeats = options.seats;
      this.selectedAddson = options.addsonItem;
      this.totalPrice();
      this.promocode = null;
      this.isValidPromocode = false;
      this.isPromocodeApplied = false;
    });

    this.$root.$on('on-timer-end', () => {
      sessionStorage.removeItem('seatsio');
      this.selectedSeats = [];
      this.selectedAddson = [];
      this.totalPrice();
      this.promocode = null;
      this.currentPromocode = '';
      this.isPromocodeApplied = false;
      this.data = {};
      this.isValidPromocode = false;
      if (this.$route.params.id) {
        const query = Object.assign({}, this.$route.query);
        query.page = 'ticketselect';
        this.$next({
          name: 'visitor.event',
          params: { id: this.$route.params.id },
          query: query,
        });
        this.$root.$emit('bookingPageChange', 'ticketselect');
      }
    });
  },
  created() {
    const seats = JSON.parse(window.localStorage.getItem('selectedSeats'));
    const addsonItem = JSON.parse(window.localStorage.getItem('selectedAddson'));
    setTimeout(() => {
      const isBookingError = localStorage.getItem('booking_error');
      if (isBookingError) {
        this.clearTickets();
        return;
      }
    }, 500);
    if (addsonItem && addsonItem.length) {
      this.selectedAddson = addsonItem;
    }
    if (seats && seats.length) {
      this.selectedSeats = seats;
    }
    this.totalPrice();
  },
  methods: {
    checkCheckBox(question){
      return (question.isRequired && question.answer.length === 0) || 'Question is required';
    },
    clearTickets(message = '', showBookingMessage = false) {
      const bodyContent =
        "We're sorry, something’s gone wrong. Please check your email & bank account for an order confirmation from us. If you can’t find anything then please refresh your browser and try to complete your order again.";
      localStorage.setItem('booking_error', true);
      if (message == '') {
        message = 'Something went wrong';
      }
      this.loading = false;
      this.$alert({
        title: message,
        subtitle: ``,
        isRefresh: false,
        showBody: true,
        bodyContent: bodyContent,
        iconName: 'error',
        resolve: async () => {
          this.overlay = true;
          this.totalPrice();
          await this.releaseTickets(false);
          await this.releaseAddonTickets();
          await this.releaseSeatsIOTickets();
          this.selectedSeats = [];
          this.selectedAddson = [];
          this.$root.$emit('emptySeat');
          window.localStorage.removeItem('selectedSeats');
          window.localStorage.removeItem('bookedEvent');
          window.localStorage.removeItem('selectedAddson');
          window.localStorage.removeItem('booking_error');
          sessionStorage.removeItem('seatsio');
          this.overlay = false;
          this.$root.$emit('stop-timer');
          const query = Object.assign({}, this.$route.query);
          query.page = 'ticketselect';
          this.removePromocode();
          this.manageUserActivity({ event: 'clearing tickets on error' + message });
          this.logUserActivity();
          this.$next({
            name: 'visitor.event',
            params: { id: this.$route.params.id },
            query: query,
          });
          this.$root.$emit('bookingPageChange', 'ticketselect');
        },
      });
    },
    readCookie(name) {
      var nameEQ = name + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    onVerify: function(response) {
      if (response) {
        this.isCaptchaChecked = true;
      }
    },
    createElement() {
      const elements = this.stripe.elements();
      card = elements.create('card', { style, hidePostalCode: true });
      card.mount('#card-element');
      card.on('change', function(event) {
        const displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
    },
    totalPrice() {
      this.price = 0;
      this.actualTicketPrice = 0;
      this.bookingFee = 0;
      for (let index = 0; index < this.selectedSeats.length; index++) {
        const element = this.selectedSeats[index];
        this.price += element.price + element.bookingFee;
        this.actualTicketPrice += element.price;
        this.bookingFee += element.bookingFee;
        if (element.seat) {
          this.ticketsIDs.push(element.seat);
        }
      }
      for (let index = 0; index < this.selectedAddson.length; index++) {
        const element = this.selectedAddson[index];
        this.price += element.selectedAttribute.price;
        this.actualTicketPrice += element.selectedAttribute.price;
      }
    },
    emptyBasket() {
      this.$confirm({
        title: `Are you sure you want to remove all your reservations?`,
        resolve: async () => {
          this.$root.$emit('emptySeat');
          this.overlay = true;
          await this.releaseTickets(false);
          await this.releaseAddonTickets();
          window.localStorage.removeItem('selectedSeats');
          window.localStorage.removeItem('bookedEvent');
          window.localStorage.removeItem('selectedAddson');
          this.overlay = false;
          this.$root.$emit('stop-timer');
          this.$root.$emit('bookingPageChange', 'ticketselect');
          const query = Object.assign({}, this.$route.query);
          query.page = 'ticketselect';
          this.removePromocode();
          this.manageUserActivity({ event: 'empty basket' });
          this.logUserActivity();
          this.$next({
            name: 'visitor.event',
            params: { id: this.$route.params.id },
            query: query,
          });
        },
      });
    },
    changeSelecion() {
      const query = Object.assign({}, this.$route.query);
      query.page = 'ticketselect';
      this.$next({
        name: 'visitor.event',
        params: { id: this.$route.params.id },
        query: query,
      });
      this.removePromocode();
      this.manageUserActivity({ event: 'change selection' });
      setTimeout(() => {
        this.$scrollToElement('ticket-options');
      }, 200);
      this.$root.$emit('bookingPageChange', 'ticketselect');
    },
    async applyPromocode() {
      const seats = JSON.parse(window.localStorage.getItem('selectedSeats'));
      this.promocode = this.promocode.toLocaleUpperCase();
      let bucketTicketUUID = [];
      for (let index = 0; index < this.selectedSeats.length; index++) {
        const element = this.selectedSeats[index];
        bucketTicketUUID.push(element.time);
      }
      this.promocodeLoading = true;
      const response = await TickerBookingService.validatePromocode({
        code: this.promocode,
        bucketTicketUUID,
        event: this.event._id,
        organiser: this.event.organiser._id,
        numberOfTickets: seats.length,
        addsons: this.selectedAddson,
      });
      if (response && response.price) {
        let priceData = response.price;
        let originalPer = priceData.originalPrice || 0;
        let discountPrice = priceData.discountPrice || 0;
        let bookingFee = priceData.bookingFee || 0;
        this.price = originalPer - discountPrice;
        console.log(this.price);
        if (this.price > 0) {
          this.price = this.price + bookingFee;
        } else if (this.price < 0) {
          this.price = 0;
        }
        this.isValidPromocode = true;
        this.isPromocodeApplied = true;
        this.currentPromocode = this.promocode;
        this.manageUserActivity({ event: 'apply promocode', data: { promocode: this.promocode } });
        this.promocodeLoading = false;
      } else {
        this.isValidPromocode = false;
        this.isPromocodeApplied = false;
        this.promocodeLoading = false;
      }
    },
    async releaseTickets(isBookedTicket) {
      if (this.selectedSeats.length) {
        const data = [];
        const times = [];
        for (let index = 0; index < this.selectedSeats.length; index++) {
          const element = this.selectedSeats[index];
          const findIndex = data.findIndex(d => d.id === element._id);
          times.push(element.time);
          if (findIndex === -1) {
            data.push({
              id: element._id,
              bucketTickets: 1,
            });
          } else {
            data[findIndex].bucketTickets = data[findIndex].bucketTickets + 1;
          }
        }
        await TickerBookingService.removeFromCart({
          eventTicketCategoryIds: data,
          userId: this.$user._id,
          times,
          isBookedTicket: isBookedTicket,
        });
        return;
      }
      return;
    },
    async updateTicketLogStatus() {
      if (this.selectedSeats.length) {
        const data = [];
        const times = [];
        for (let index = 0; index < this.selectedSeats.length; index++) {
          const element = this.selectedSeats[index];
          times.push(element.time);
          const findIndex = data.findIndex(d => d.id === element._id);
          if (findIndex === -1) {
            data.push({
              id: element._id,
              bucketTickets: 1,
            });
          } else {
            data[findIndex].bucketTickets = data[findIndex].bucketTickets + 1;
          }
        }
        await TickerBookingService.updateLogsStatus({
          eventTicketCategoryIds: data,
          userId: this.$user._id,
          times,
          isBookedTicket: true,
        });
        return;
      }
      return;
    },
    async releaseAddonTickets() {
      if (this.selectedAddson.length) {
        const data = [];
        const times = [];
        for (let index = 0; index < this.selectedAddson.length; index++) {
          const element = this.selectedAddson[index];
          times.push(element.time);
          data.push({
            id: element.addon._id,
            bucketTickets: 1,
            attributeId: element.selectedAttribute.id,
          });
        }
        await AddonService.releaseTicket({
          addsonsIds: data,
          event: this.event._id,
          times,
        });
        return;
      }
      return;
    },
    async releaseSeatsIOTickets() {
      this.ticketsIDs = [];
      for (let index = 0; index < this.selectedSeats.length; index++) {
        const element = this.selectedSeats[index];
        if (element.seat) {
          this.ticketsIDs.push(element.seat);
        }
      }
      if (this.ticketsIDs && this.ticketsIDs.length > 0) {
        await PromoterEventService.seatsIoRelease({
          eventId: this.event.seatsIOEventId,
          tickets: this.ticketsIDs,
          holdToken: window.localStorage.getItem('holdToken'),
        });
      }
    },
    async createPaymentMethod() {
      const res = this.stripe.createPaymentMethod({
        type: 'card',
        card: card,
      });
      return res;
    },
    async stripePayout(times) {
      let result = await this.createPaymentMethod();
      if (result.error) {
        this.clearTickets(result.error.message);
        this.isSumitted = false;
        this.loading = false;
        this.overlay = false;
        return;
      } else {
        let response = await PaymentService.createPaymentIntent({
          amount: this.price,
          event: this.event.title,
          eventId: this.event._id,
          paymentMethodId: result.paymentMethod.id,
          email: this.data.email,
          userId: this.$user._id,
          ticketUniqueIds: times,
        });
        if (response && response.status) {
          const errorMessage = response.data.message || response.data.error || response.data;
          this.clearTickets(errorMessage);
          this.isSumitted = false;
          this.loading = false;
          this.overlay = false;
        } else {
          let stripeResult;
          if (response.requires_action) {
            stripeResult = await this.stripe.confirmCardPayment(response.payment_intent_client_secret);
            if (stripeResult.error) {
              this.clearTickets(stripeResult.error.message);
              return;
            } else {
              return response.paymentIntentId;
            }
          }
        }
        return response.paymentIntentId;
      }
    },
    isSurveyFill(survey) {
      let isProvideAnyAnswer = false;
      for (let index = 0; index < survey.length; index++) {
        const element = survey[index];
        const answeredQuestions = element.questions.find((q) => q.answer)
        if(answeredQuestions) {
          isProvideAnyAnswer = true
        }
      }
      return (isProvideAnyAnswer) ? survey : [];
    },
    async payOut() {
      this.isSumitted = true;
      this.overlay = true;
      this.loading = true;
      this.$root.$emit('checkStripeEnabled');
      this.$root.$emit('addTimeToCart');
      if (this.$refs.form.validate() && this.$refs.form2.validate() && this.$refs.form3.validate() && this.isCaptchaChecked) {
        if (
          (this.event.infoMessage || this.event.timingInformation || this.event.additionalInformation) &&
          !this.is_age_information
        ) {
          this.$error('Please accept Terms and Conditions.');
          this.isSumitted = false;
          this.overlay = false;
          this.loading = false;
          return;
        }
        if (!this.isFreeBooking && this.price != 0) {
          let result = await this.createPaymentMethod();
          if (result.error) {
            this.$error(result.error.message);
            this.isSumitted = false;
            this.overlay = false;
            this.loading = false;
            return;
          }
        }

        this.data.send_marketing_emails = this.send_marketing_emails;
        this.data.can_send_mail = this.can_send_mail;
        this.data.is_age_information = this.is_age_information;
        this.data.price = this.price;
        this.data.eventId = this.event._id;
        if (this.isValidPromocode) {
          this.data.promoCode = this.promocode;
        }
        if (!this.isFreeBooking && this.price != 0) {
          this.data.isHavePrice = true;
        } else if (this.isFreeBooking) {
          this.data.isFreeTicket = true;
        } else {
          this.data.isPriceZero = true;
        }
        const times = [];
        for (let index = 0; index < this.selectedSeats.length; index++) {
          const element = this.selectedSeats[index];
          times.push(element.time);
        }
        this.data.ticketUniqueIds = times;
        if (localStorage.getItem('utm_source')) {
          const utmData = JSON.parse(localStorage.getItem('utm_source'));
          if (utmData) {
            this.data.utmCode = utmData.utm_source;
            this.data.utmMedium = utmData.utm_medium;
            this.data.utmCampaign = utmData.utm_campaign;
          }
        }
        if (this.selectedAddson && this.selectedAddson.length) {
          this.data.addsons = this.selectedAddson;
        }
        this.data.bookingSessionId = this.$uuid.v4();
        this.data.surveyDetails = this.isSurveyFill(this.event.survey)
        console.log('🚀 ~ file: event-place-order.vue:1049 ~ payOut ~ this.event.survey', this.event.surveyDetails);
        const response = await TickerBookingService.purchaseTickets(this.data);
        let successReponse = true;
        if (response && response.status && (response.status === 500 || response.status === 422)) {
          successReponse = false;
        }
        this.ticketsIDs = [];
        for (let index = 0; index < this.selectedSeats.length; index++) {
          const element = this.selectedSeats[index];
          if (element.seat) {
            this.ticketsIDs.push(element.seat);
          }
        }
        let seatsIOResponse = true;
        if (this.ticketsIDs && this.ticketsIDs.length > 0) {
          seatsIOResponse = await PromoterEventService.seatsIoConfirm({
            eventId: this.event.seatsIOEventId,
            tickets: this.ticketsIDs,
            holdToken: window.localStorage.getItem('holdToken'),
          });
          if (!seatsIOResponse || seatsIOResponse === undefined) {
            seatsIOResponse = false;
          }
          if (
            seatsIOResponse &&
            seatsIOResponse.status &&
            (seatsIOResponse.status === 500 || seatsIOResponse.status === 422)
          ) {
            seatsIOResponse = false;
          }
        }
        if (response && successReponse && seatsIOResponse) {
          let stripeResponse;
          if (!this.isFreeBooking && this.price != 0) {
            stripeResponse = await this.stripePayout(times);
            if (!stripeResponse || stripeResponse === undefined) {
              this.isSumitted = false;
              this.loading = false;
              this.overlay = false;
              return;
            }
          }
          const confirmResponse = await TickerBookingService.confirmTickets({
            ticketAssignmentId: response._id,
            paymentIntentId: stripeResponse,
            event: this.event._id,
            bucketTicketsUUID: times,
            bookingSessionId: this.data.bookingSessionId,
            addsons: this.selectedAddson && this.selectedAddson.length > 0 ? this.selectedAddson : 0,
          });
          let successReponseConfrim = true;
          if (
            confirmResponse &&
            confirmResponse.status &&
            (confirmResponse.status === 500 || confirmResponse.status === 422)
          ) {
            successReponseConfrim = false;
          }
          if (confirmResponse && successReponseConfrim) {
            this.loading = false;
            this.overlay = false;
            this.manageUserActivity({ event: 'place order', data: this.selectedSeats });
            this.logUserActivity();
            localStorage.removeItem('freeTickets');
            this.loading = false;
            await this.updateTicketLogStatus(true);
            await this.releaseAddonTickets();
            window.localStorage.removeItem('selectedSeats');
            window.localStorage.removeItem('selectedAddson');
            window.localStorage.removeItem('bookedEvent');
            sessionStorage.removeItem('seatsio');
            localStorage.removeItem('utm_source');
            this.$root.$emit('placedOrder', confirmResponse.ticketAssignmentLongId);
            this.$root.$emit('stop-timer');
            const query = Object.assign({}, this.$route.query);
            query.page = 'ticketbooked';
            query.id = confirmResponse.ticketAssignmentLongId;
            this.$next({
              name: 'visitor.event',
              params: { id: this.$route.params.id },
              query: query,
            });
            this.$root.$emit('bookingPageChange', 'ticketbooked');
          } else {
            let message = '';
            if (confirmResponse && confirmResponse.data && confirmResponse.data.message) {
              message = confirmResponse.data.message;
            }
            this.clearTickets(message, true);
            this.loading = false;
            this.isSumitted = false;
            this.overlay = false;
          }
        } else {
          let message = '';
          if (response && response.data && response.data.message) {
            message = response.data.message;
          }
          this.clearTickets(message);
          this.isSumitted = false;
          this.loading = false;
          this.overlay = false;
        }
      } else {
        this.isSumitted = false;
        this.overlay = false;
        this.loading = false;
      }
    },
    back() {
      const query = Object.assign({}, this.$route.query);
      query.page = 'ticketselect';
      this.$next({
        name: 'visitor.event',
        params: { id: this.$route.params.id },
        query: query,
      });
      this.$root.$emit('bookingPageChange', 'ticketselect');
    },
    clearPromocode() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
      this.$emit('close');
    },
    removePromocode() {
      this.isValidPromocode = false;
      this.totalPrice();
      this.manageUserActivity({ event: 'remove promocode', data: { promocode: this.promocode } });
      this.promocode = null;
      this.isPromocodeApplied = false;
      this.data.promoCode = null;
      this.currentPromocode = '';
      this.cancel();
    },
    checkAnswer(questionIndex, surveyIndex) {
      this.event.survey[surveyIndex].questions[questionIndex].answer = this.selected;
      this.$forceUpdate();
    },
  },
};
</script>
