<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card-title class="px-12 py-8 justify-space-between grey lighten-4">
      <span class="subtitle-1 primary--text">Ticket Booked!</span>
    </v-card-title>

    <v-divider />

    <div class="pa-4 pa-lg-12">
      <v-list>
        <v-list-item class="justify-start px-0">
          <v-list-item-content style="flex: 130px 0">
            <v-list-item-subtitle class="primary--text text-bold">Purchaser:</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-subtitle class="text--primary">
              {{ bookedData.title }} {{ bookedData.firstName }} {{ bookedData.lastName }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-content style="flex: 130px 0">
            <v-list-item-subtitle class="primary--text text-bold">Event Date:</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-subtitle class="text--primary" v-if="bookedData && bookedData.event">
              {{ bookedData.event.startsAt | fullday }} {{ bookedData.event.startsAt | time }} - {{ bookedData.event.endsAt | time }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="justify-start px-0">
          <v-list-item-content style="flex: 130px 0">
            <v-list-item-subtitle class="primary--text text-bold">Ticket(s):</v-list-item-subtitle>
          </v-list-item-content>
              <v-list-item-content v-for="(price, index) in prices" :key="index">
                <v-list-item-subtitle class="text--primary">
                  {{ price }}
                </v-list-item-subtitle>
              </v-list-item-content>

          <v-list-item-content style="flex: 130px 0" v-if="bookedData.event && bookedData.event.doorsAt">
            <v-list-item-subtitle class="primary--text text-bold">Door Time:</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-if="bookedData.event && bookedData.event.doorsAt">
            <v-list-item-subtitle class="text--primary">
              {{ bookedData.event.doorsAt | fullday }}
              {{ bookedData.event.doorsAt | time }}
            </v-list-item-subtitle>
          </v-list-item-content>
            
        </v-list-item>

        <v-list-item two-line class="justify-start px-0" v-if="bookedData.addsons && bookedData.addsons.length">
          <v-list-item-content style="flex: 130px 0">
            <v-list-item-subtitle class="primary--text text-bold">Add Ons:</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-row>
              <v-col xs="3" sm="12" md="3" v-for="(addon, index) in addsons" :key="index">
                {{ addon }}
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="justify-start px-0" v-if="bookedData.payment && bookedData.payment.discount">
          <v-list-item-content style="flex: 130px 0">
            <v-list-item-subtitle class="primary--text text-bold">Discount applied:</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-subtitle class="text--primary">{{
              bookedData.payment.discount | currency
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item class="justify-start px-0">
              <v-list-item-content style="flex: 130px 0">
                <v-list-item-subtitle class="primary--text text-bold">Charged:</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle class="text--primary">&#8356;0:00</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->

        <v-list-item class="justify-start px-0">
          <v-list-item-content style="flex: 130px 0">
            <v-list-item-subtitle class="primary--text text-bold">Total Paid:</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-subtitle v-if="bookedData && bookedData.payment" class="text--primary">{{
              bookedData.payment.amount | currency
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-card flat v-show="possibleDeliveryMethod !== 'boxofficecollection'">
        <v-card-title class="primary--text my-4">
          Your electronic tickets
        </v-card-title>
        <v-card-subtitle v-if="bookedData.payment && bookedData.payment.email" class="py-0">
          Your confirmation has been sent to this address:
          <span class="font-weight-bold">{{ bookedData.payment.email }}</span>
        </v-card-subtitle>

        <v-card-subtitle>
          When you arrive at the venue, please present the below tickets on your mobile. You don't have to print them.
        </v-card-subtitle>
      </v-card>

      <v-row class="ml-2">
        <v-col>
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on"><span class="primary--text">Add to calendar</span></a>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in addCalendarOptions"
                :key="index"
              >
                <v-list-item-icon class="mr-2">
                  <a><v-icon color="primary">{{item.icon}}</v-icon></a> 
                </v-list-item-icon>
                <v-list-item-title @click="addEventToCalender(item, event)"><a>{{ item.title }}</a></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-card flat v-show="possibleDeliveryMethod === 'boxofficecollection'">
        <v-card-title class="primary--text my-4">
          Your order
        </v-card-title>
        <v-card-subtitle v-if="bookedData.payment && bookedData.payment.email" class="py-0">
          Delivery - box office collection
          <br />
          Your confirmation has been sent to this address: <span class="font-weight-bold">{{ bookedData.payment.email }}</span>
          <br />
          Please take your order confirmation and ID along to the venue where your tickets will be available to collect.
        </v-card-subtitle>
      </v-card>

      <v-row v-if="possibleDeliveryMethod !== 'boxofficecollection'">
        <v-col cols="12" sm="6" md="4" v-for="(ticket, index) in tickets" :key="index">
          <v-card flat v-if="!ticket.isRefund">
            <v-card-text class="cursor-none">
              <qr-code :text="qrcodeData(ticket)"></qr-code>
            </v-card-text>
            <v-card-subtitle class="py-0">
              Ticket: {{ ticket.category.name }}{{ ticket.seat ? ' - ' + ticket.seat : '' }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      

      <v-row class=" hidden-print-only">
        <v-col cols="12" sm="12">
          <v-card-subtitle class="text-bold text--primary">Share with friends</v-card-subtitle>
          <v-card-text>
            <share-with-friends :event="event" />
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-btn block color="primary" outlined @click="printTicket()" >
                  <v-icon aria-hidden="false" size="16" class="pr-2"> mdi-printer </v-icon>
                  <span>Print your ticket</span>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-btn block color="primary" :loading="loading" class="" @click="resendTicket()">
                  <v-icon aria-hidden="false" size="16" class="pr-2"> mdi-email </v-icon>
                  <span>Resend ticket confirmation</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { PromoterEventService } from '@services';
import _ from 'lodash';
import ShareWithFriends from './share-with-friends';

export default {
  components: {
    ShareWithFriends,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      bookedData: {},
      prices: [],
      addsons: [],
      tickets: [],
      hasETickets: false,
      loading: false,
      overlay: true,
      possibleDeliveryMethod: '',
      addCalendarOptions: this.addEventCalendarOptions(),
    };

  },
  async mounted() {
    this.$root.$on('placedOrder', id => {
      this.overlay = true;
      this.getData(id);
    });
  },
  async created() {
    if (this.$route.query.id) {
      this.getData(this.$route.query.id);
    }
  },
  methods: {
    async getData(id) {
      const response = await PromoterEventService.getEventByLongId(id);
      this.overlay = false;
      window.localStorage.removeItem('selectedSeats');
      if (response) {
        this.bookedData = response;
        if(response && response.event && response.event.possibleDeliveryMethods && response.event.possibleDeliveryMethods[0]) {
          this.possibleDeliveryMethod = response.event.possibleDeliveryMethods[0].method;
        }
        let tickets = this.bookedData.tickets.sort((a, b) => {
          if (a.category.addonForCategories.length && !b.category.addonForCategories.length) {
            return 1;
          } else if (b.category.addonForCategories.length) {
            return -1;
          } else {
            return a.category.name.localeCompare(b.category.name);
          }
        });

        const prettyPrintPrice = price => {
          return (price / 100).toFixed(2);
        };
        this.prices = [];
        let filteredtickets = tickets.filter((ticket) => !ticket.isRefund)
        _.forEach(
          _.groupBy(filteredtickets, ticket => ticket.category._id),
          tickets => {
            const ticket = tickets[0];
            const { category } = ticket;
            const count = tickets.length;
            const bookingFee = ticket.boughtBookingFee;
            const price = ticket.boughtPrice + bookingFee;
            let seat = '';
            if(tickets && tickets.length && tickets[0].seat) {
              seat = ' (';
              for(let index = 0; index < tickets.length; index++) {
                seat += tickets[index].seat + ',';
              }
              seat = seat.replace(/,\s*$/, "");
              seat += ') ';
            }
            this.prices.push(
              count +
                ' x ' +
                category.name +
                seat +
                ': ' +
                '£' +
                prettyPrintPrice(price) +
                ' (incl. £' +
                prettyPrintPrice(bookingFee) +
                ' fees)'
            );
          }
        );
        this.tickets = tickets;
        this.addsons = [];
        let groupaddson = [];
        if (this.bookedData && this.bookedData.addsons && this.bookedData.addsons.length) {
          for(let i = 0; i < this.bookedData.addsons.length; i++) {
            let element = this.bookedData.addsons[i];
            const bookingFee = element.paidBookingFee;
            const price = element.price + bookingFee;
            groupaddson.push({ name :
                  element.addon.name + " "+ element.selectedSize +
                  ': ' +
                  '£' +
                  prettyPrintPrice(price) 
            });
          }
          const groupAddonData = _.groupBy(groupaddson, 'name');
          for (const key in groupAddonData) {
            if (Object.hasOwnProperty.call(groupAddonData, key)) {
              this.addsons.push(`${groupAddonData[key].length} x ${key}`)
            }
          }
        }
      }
    },
    async resendTicket() {
      this.loading = true;
      await PromoterEventService.resendTicketEmail(this.$route.query.id);
      this.loading = false;
    },
    printTicket() {
      let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

      mywindow.document.write('<html>');
      mywindow.document.write('<head>');
      mywindow.document.write(`<title>${this.event.title} - ${this.$route.query.id}</title>`);
      mywindow.document.write(
        '<link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet" />'
      );
      mywindow.document.write(
        '<link href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css" rel="stylesheet" />'
      );
      mywindow.document.write(
        '<link href="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css" rel="stylesheet" />'
      );
      mywindow.document.write('<style type="text/css" id="vuetify-theme-stylesheet">');
      mywindow.document.write(document.getElementById('vuetify-theme-stylesheet').innerHTML);
      mywindow.document.write('</style>');
      mywindow.document.write('</head>');
      mywindow.document.write('<body>');
      mywindow.document.write('<div id="app">');
      mywindow.document.write(
        '<div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">'
      );
      mywindow.document.write('<div class="v-application--wrap">');
      mywindow.document.write('<main class="v-main" data-booted="true" style="padding: 0px">');
      mywindow.document.write('<div class="v-main__wrap"><div class="container">');
      mywindow.document.write(document.getElementById('print').innerHTML);
      mywindow.document.write('</div></div>');
      mywindow.document.write('</main>');
      mywindow.document.write('</div>');
      mywindow.document.write('</div>');
      mywindow.document.write('</div>');
      mywindow.document.write('</body>');
      mywindow.document.write('</html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      setTimeout(() => {
        mywindow.print();
        mywindow.close();
      }, 2000); // PATCH: wait until cdn downloaded

      return true;
    },
    qrcodeData(ticket) {
      return ticket.qrCode;
      // return Buffer.from(ticket.qrCode, 'base64').toString();
    },
  },
};
</script>
