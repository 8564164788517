<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row class="ma-0">
      <v-col cols="12" sm="3">
        <v-text-field
          type="text"
          outlined
          dense
          clearable
          v-model="data.firstName"
          required
          name="firstName"
          :rules="[
            v => !!v || 'First Name is required',
            v => (v && v.length < 20) || 'First name must less than 20 character',
          ]"
          label="First Name"
          placeholder="John Doe"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          type="text"
          outlined
          dense
          clearable
          v-model="data.lastName"
          required
          name="lastName"
          :rules="[
            v => !!v || 'Last Name is required',
            v => (v && v.length < 20) || 'Last name must less than 20 character',
          ]"
          label="Last Name"
          placeholder="John Doe"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          type="email"
          outlined
          dense
          clearable
          required
          v-model="data.email"
          :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
          name="email"
          label="Email"
          placeholder="johndoe@gmail.com"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn block color="primary" :loading="loading" @click="subscribe()">
          Subscribe
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from 'moment';
import { PromoterEventService } from '@services';
import ShareWithFriends from './share-with-friends';

export default {
  props: {
    event: Object,
  },
  data() {
    return {
      data: {},
      valid: false,
      loading: false,
    };
  },
  methods: {
    async subscribe() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.data.event = this.event._id;
        this.data.utmCode = this.$route.query.utm_source;
        await PromoterEventService.subscriptions(this.data);
        this.loading = false;
        this.data = {};
        this.$refs.form.reset();
      }
    },
  },
};
</script>
