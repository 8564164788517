<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card outlined>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              dense
              outlined
              name="oldPassword"
              clearable
              :rules="[v => !!v || 'Old Password is required']"
              v-model="data.oldPassword"
              label="Old Password"
              id="oldPassword"
            ></v-text-field>
            <v-text-field
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
              dense
              outlined
              clearable
              name="newPassword"
              v-model="data.newPassword"
              :rules="[v => !!v || 'New Password is required']"
              label="New Password"
              id="newPassword"
            ></v-text-field>
            <v-text-field
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              @click:append="show3 = !show3"
              dense
              outlined
              clearable
              name="confirmPassword"
              v-model="newConfimPassword"
              :rules="[v => !!v || 'Confirm Password is required', checkPassword]"
              label="Confirm Password"
              id="confirmPassword"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="cancel()">Cancel</v-btn>
        <v-btn width="100" color="primary" :loading="loading" @click="save()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { UserService } from '@services';
export default {
  data() {
    return {
      valid: false,
      data: {
        oldPassword: '',
        newPassword: '',
      },
      newConfimPassword: '',
      show1: false,
      show2: false,
      show3: false,
      loading: false,
    };
  },
  computed: {
    checkPassword() {
      return this.newConfimPassword === this.data.newPassword || 'The password confirmation does not match.';
    },
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        this.$confirm({
          title: `Are you sure you want to change password?`,
          resolve: async () => {
            this.loading = true;
            await UserService.editpassword(this.$user._id, this.data).then(res => {
              if (res !== false) {
                this.data = {
                  oldPassword: '',
                  newPassword: '',
                };
                this.$refs.form.reset();
                this.newConfimPassword = '';
              }
            });
            this.loading = false;
          },
        });
      }
    },
    cancel() {
      this.$next({ name: 'visitor.account.view' });
    },
  },
};
</script>
