<template>
  <v-row>
    <v-col cols="12" sm="10" class="mx-auto">
      <v-card class="mb-12 pa-4 rounded-0" elevation="0" outlined>
        <v-card-title class="font-weight-bold">Your Orders</v-card-title>
        <!-- <v-card-text>
          <v-alert color="red" text>
            <span class="text--primary body-1"> You don't have any orders yet. </span>
          </v-alert>
        </v-card-text> -->
        <v-card-text>
          <v-data-table :headers="headers" :items="orders" hide-default-footer>
            <template v-slot:[`item.date`]="{ item }">
              <span>{{ item.createdAt | dateTime }}</span>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span>{{ (item.event || {}).title }}</span>
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              <span>{{ (item.event || {}).startsAt | dateTime }}</span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>{{ item.payment.amount | currency }}</span>
            </template>
            <template v-slot:[`item.ticketStatus`]="{ item }">
              <span>{{ (item.ticketStatus === 'BOOKED') ? 'BOOKED' : 'REFUNDED' }}</span>
            </template>
            <template v-slot:[`item.ticketDetails`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :disabled="item.ticketStatus === 'REFUND'" color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="goToOrdePage(item)">
                    mdi-form-select
                  </v-icon>
                </template>
                <span>Ticket Details</span>
              </v-tooltip>
            </template>
            <template v-slot:footer>
              <v-pagination
                v-if="totalPage"
                class="text-right text-body-2 py-2 "
                circle
                :total-visible="5"
                color="primary"
                v-model="query.page"
                :length="totalPage"
              ></v-pagination>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { UserService } from '@services';
export default {
  data() {
    return {
      query: {
        search: '',
        page: 1,
      },
      totalPage: '',
      headers: [
        {
          text: 'Event Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '200px',
        },
        {
          text: 'Event Date',
          sortable: false,
          value: 'startDate',
          width: '200px',
        },
        {
          text: 'Booking Date',
          sortable: false,
          value: 'date',
          width: '200px',
        },
        {
          text: 'Amount',
          sortable: false,
          value: 'amount',
          width: '100px',
        },
        {
          text: 'Status',
          sortable: false,
          value: 'ticketStatus',
          width: '100px',
        },
        {
          text: 'Tickets Details',
          sortable: false,
          value: 'ticketDetails',
          width: '150px',
          align:'center'
        },
      ],
      orders: [],
    };
  },
  created() {
    this.getMyOrders();
  },
  methods: {
    async getMyOrders() {
      const response = await UserService.getMyOrders(this.query);
      if (response) {
        this.orders = response.orders;
        this.totalPage = Number(parseInt((response.totalCount + 10 - 1) / 10));
      }
    },
    goToOrdePage(item) {
      let url = `${window.location.protocol}//${window.location.host}/${item.event.slug}?page=ticketbooked&id=${item.longId}`;
      window.open(url)
    }
  },
  watch: {
    'query.page': function() {
      this.getMyOrders();
    },
  },
};
</script>
